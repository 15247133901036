import {
  Avatar,
  Box,
  Button,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import UiTable from "../components/UiTable";
import TableTotalCount from "../components/TableTotalCount";
import SearchActionComponent from "../components/SearchActionComponent";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import copyIcon from "../assets/copy.png";
import pauseIcon from "../assets/pause.png";
import { BootstrapTooltip } from "../utils/tooltip";
import { FaPauseCircle, FaPlayCircle } from "react-icons/fa";
import CustomConfirmationDialog from "../UIComponents/CustomConfirmationDialog";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../components/Loader";
import { MdPreview } from "react-icons/md";
import CommonDateFilterPanel from "../components/Filter/CommonDateFilterPanel";
import spinnerIcon from "../assets/spinner.svg";
import SwipeableDrawerComponent from "../components/SwipeableDrawerComponent ";
import CustomFilter from "../components/Filter/CustomFilter";
import FilteredResults from "../components/FilteredResults";
import formatDate, { formatDateNew } from "../utils/formateDate";
import dayjs from "dayjs";
import ExportButton from "../components/ExportButton";
import { handleDownload } from "../utils/download";
import usePermission from "../utils/permissions";
import CustomSwitch from "../UIComponents/CustomSwitch";
import AddColumn from "../components/transactions/AddColumn";
import ToggleColumn from "../UIComponents/ToggleColumn";
const dateFilterLayout = {
  select: "ALL_TIME",
  from_time: "",
  to_time: "",
};

const filterSelectOptions = [
  { label: "Custom", value: "CUSTOM" },
  { label: "Today", value: "TODAY" },
  { label: "Yesterday", value: "YESTERDAY" },
  { label: "This Week", value: "THIS_WEEK" },
  { label: "This Month", value: "THIS_MONTH" },
  { label: "All Time", value: "ALL_TIME" },
];

const initialFilterOptions = {
  offer_status: [],
  status: [],
  reward_currency: [],
};

//css

const moreFilterButtonStyle = {
  height: "45px",
  padding: "14px 20px 14px 20px",
  borderRadius: "5px",
  border: "1px solid #D9D9D9",
  background: "#fff",
};

const moreFilterTextStyle = {
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "17.5px",
  fontFamily: "Proxima Nova A",
  color: "#26292D",
};

const TData = () => {
  return [
    { key: "id", displayName: "Offer ID", flag: true },
    { key: "name", displayName: "Offer Name", flag: true },
    { key: "game_type", displayName: "Game Type", flag: true },
    { key: "reward_currency", displayName: "Reward Currency", flag: true },
    { key: "offer_status", displayName: "Offer Status", flag: true },
    { key: "duration", displayName: "Duration", flag: true },
    { key: "visibility", displayName: "Visibility", flag: true },
    // { key: "category_id", displayName: "Category", flag: false },
    // { key: "subcategory_id", displayName: "Subcategory", flag: false },
    // { key: "merchant_id", displayName: "Merchant", flag: false },
  ];
};

export default function ViewOffers() {
  const [fieldsName, setFieldName] = useState(TData);
  const [columnFields, setColumnFields] = useState([]);

  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState("All Offers");
  const [editData, setEditData] = useState(false);
  const [flag, setFlag] = useState(true);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [offerStatus, setOfferStatus] = useState(false);
  const anchor = "right";
  const [dateFilter, setDateFilter] = useState(dateFilterLayout);
  const [filterConfig, setFilterConfig] = useState([]);
  const [doneButton, setDoneButton] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showFilter, setShowFilter] = useState([]);
  const [filter, setFilter] = useState({
    right: false,
  });
  const [offers, setOffers] = useState({
    data: [],
    paginationModel: {
      pageSize: 10,
      page: 0,
    },
    totalCount: 0,
    allOfferCount:0,
    pendingOfferCount:0,
    denyOfferCount:0,
  });
  const navigate = useNavigate();
  const IdRef = useRef("");
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const isAuth = useSelector((store) => store.AuthReducer.token);
  const token = useSelector((store) => store.AuthReducer.token);
  const permission = usePermission();
  console.log(permission, "permission");

  const totalTab = [
    { name: "All Offers", totalCount: offers?.allOfferCount },
    { name: "Pending", totalCount: offers?.pendingOfferCount },
    { name: "Denied", totalCount: offers?.denyOfferCount },
  ];

  function handleEdit(e, params) {
    e.stopPropagation();
    IdRef.current = params?.id;
    const filterData = offers?.data?.find((e) => e?.id === params?.id);
    // console.log(filterData.offer_status)
    if (permission?.UPDATE) {
      if (filterData?.offer_status === "ACTIVE") {
        setMessage("Do you really want to Pause this offer?");
      } else {
        setMessage("Do you really want to start this offer?");
      }
      setEditData(true);
    }
    // console.log(`delete ${params.id}`);
  }

  const handleToggleColumn = (i) => {
    console.log(i, "index");
    const data = fieldsName.map((e, index) => {
      if (index === i) {
        e.flag = !e.flag;
      }
      return e;
    });

    setFieldName(data);
  };

  // Debounce utility function
  const debounce = (func, delay) => {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), delay);
    };
  };

  // Debounced handleVisibility function
  const handleVisibility = debounce((e, params) => {
    e.stopPropagation();

    const id = params.id;
    if (!id) return;

    const filterData = offers.data.find((e) => e.id === id);
    const url = `${BaseUrl}/api/admin/offer/${id}`;
    console.log(params?.row?.offer_visibility, "params");
    axios
      .patch(
        url,
        {
          visibility: params?.row?.offer_visibility === 0 ? 1 : 0,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOfferStatus((prev) => !prev);
        toast.success("offer visibility changed successfully");
      })
      .catch((err) => {
        console.log(
          "error while status updating",
          err?.response?.data?.message
        );
        toast.error(err?.response?.data?.message);
      });
  }, 500);

  function handleStatusChangeByID(id) {
    if (!id) return;

    const filterData = offers.data.find((e) => e.id === id);
    console.log(filterData, "filterData");
    // toast.success("Status Changed Successfully");
    const url = `${BaseUrl}/api/admin/offer/${id}`;
    axios
      .patch(
        url,
        {
          status: filterData?.offer_status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOfferStatus((prev) => !prev);
        toast.success("offer status changed successfully");
      })
      .catch((err) => {
        console.log(
          "error while status updating",
          err?.response?.data?.message
        );
        toast.error(err?.response?.data?.message);
      });
  }

  function handleCopy(e, params) {
    e.stopPropagation();
    if (permission?.CREATE) {
      navigate("/create-offers", { state: { id: params.id } });
    }
    // console.log('/create-offers', { state: { id: params.id } })
  }

  const handlePriority = (e, params, value) => {
    e.stopPropagation();
    IdRef.current = params?.id;
    if (params.formattedValue != value) {
      console.log(value);
    }
  };

  const handleRowClick = (params) => {
    if (permission?.UPDATE) {
      navigate(`/edit-offers/${params.id}`);
    } else {
      toast.error("You don't have permission");
    }
  };

  const handlePreview = (e, params) => {
    e.stopPropagation();
    // if (permission?.UPDATE) {
    navigate(`/preview-offer/${params.id}`, {
      state: { UPDATE: permission?.UPDATE },
    });
    // }
  };

  // POST

  const getOfferSetFunction = async (status, allData, isPagination = false) => {
    if (status !== "download") {
      setLoading(true);
    }
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const token = isAuth;

    if (!isPagination) {
      const paginationValue = {
        page: 0,
        pageSize: offers?.paginationModel.pageSize,
      };
      handlePaginationModel(paginationValue);
    }

    const body = {
      page: isPagination ? offers?.paginationModel.page + 1 : 1,
      itemsPerPage: offers?.paginationModel.pageSize,
      ...(search && { search }), // Include search parameter if it exists
    };
   
    if(activeTab==="Pending"){
      body.offerApprovalStatus="PENDING";
    }else if(activeTab==="Denied"){
      body.offerApprovalStatus="DENIED";
    }

    if (status === "done") {
      setShowFilter(filterConfig);
    }

    // if status is download or empty
    if (!status || status === "download") {
      showFilter?.forEach((res) => {
        if (res?.state?.length > 0) {
          body[res?.name] = res?.state;
        }
      });
    } else if (status === "clear") {
      allData?.forEach((res) => {
        if (res?.state?.length > 0) {
          body[res?.name] = res?.state;
        }
      });
    } else if (status === "done") {
      filterConfig?.forEach((res) => {
        if (res?.state?.length > 0) {
          body[res?.name] = res?.state;
        }
      });
    }

    // Include dateFilter if necessary
    if (dateFilter?.select) {
      body["day"] = dateFilter?.select;
    }

    // Include custom dateFilter if necessary
    if (
      dateFilter?.select &&
      dateFilter?.select === "CUSTOM" &&
      dateFilter?.to_time &&
      dateFilter?.from_time
    ) {
      let startDate = formatDateNew(dateFilter?.from_time ?? "");
      let endDate = formatDateNew(dateFilter?.to_time ?? "");
      body["dateStart"] = startDate;
      body["dateEnd"] = endDate;
    }

    if (status === "download") {
      body["total_count"] = offers?.totalCount;
      handleDownload(baseUrl, body, token, "viewOffers");
      return;
    }

    // If body is empty, return
    if (Object.entries(body).length === 0) return;

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Adjust content type as needed
    };

    try {
      const response = await axios.post(`${baseUrl}/api/offers`, body, {
        headers,
      });

      console.log(response, "offer response");

      let formateData = response?.data?.data?.map((e) => {
        return {
          id: e.id,
          name: e.offer_name,
          game_type: e.game_type,
          reward_currency: e.reward_currency,
          offer_status: e.status,
          duration: e.duration + " Days",
          visibility: e?.visibility,
          offer_visibility: e?.visibility,
          offer_status: e.status,
          action: {
            copyBtn: copyIcon,
            priority: e.offer_name.length < 10 ? "high" : "low",
            editBtn:
              e.status == "ACTIVE" ? (
                <FaPauseCircle
                  style={{
                    height: "21px",
                    width: "21px",
                    color: permission?.UPDATE ? "black" : "gray",
                  }}
                />
              ) : (
                <FaPlayCircle
                  style={{
                    height: "21px",
                    width: "21px",
                    color: permission?.UPDATE ? "black" : "gray",
                  }}
                />
              ),
          },
        };
      });

      setStartDate(response.data.day_start_date ?? "");
      setEndDate(response.data.day_end_date ?? "");

      setOffers((prev) => ({
        ...prev,
        data: formateData ?? [],
        totalCount: response?.data?.offerCount ?? 0,
        allOfferCount: response?.data?.allOfferCount?? 0,
        pendingOfferCount: response?.data?.pendingOfferCount?? 0,
        denyOfferCount: response?.data?.denyOfferCount?? 0,
      }));

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  console.log(startDate, "startDate");

  const handleRemove = (name, value) => {
    setFilterConfig((prevOptions) => {
      const updatedOptions = [...prevOptions];
      console.log(updatedOptions, "testing");
      const updatedData = updatedOptions?.map((filter) =>
        filter?.name === name
          ? {
              ...filter,
              state: filter.state.filter((res) => res !== value),
            }
          : filter
      );
      return updatedData;
    });
  };

  const removeFilters = (name, value) => {
    handleRemove(name, value);
    const latestData = showFilter.map((filter) =>
      filter?.name === name
        ? {
            ...filter,
            state: filter.state.filter((res) => res !== value),
          }
        : filter
    );
    setShowFilter((prevOptions) => {
      const updatedData = prevOptions?.map((filter) =>
        filter?.name === name
          ? {
              ...filter,
              state: filter.state.filter((res) => res !== value),
            }
          : filter
      );

      return updatedData;
    });

    getOfferSetFunction("clear", latestData);
  };

  const handleDateFilter = (name, value) => {
    setDateFilter((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (value === "ALL_TIME") {
      setDateFilter((prev) => ({
        ...prev,
        from_time: { unset: true },
        to_time: { unset: true },
      }));
    }

    if (value === "CUSTOM") {
      setDateFilter((prev) => ({
        ...prev,
        from_time: { unset: true },
        to_time: { unset: true },
      }));
    }
  };

  const handleClearFilter = (initializeValueByName) => () => {
    setFilterConfig((prev) => {
      const updatedData = prev?.map((filter) => ({ ...filter, state: [] }));
      return updatedData;
    });
  };

  const handleDoneButton = () => {
    // setFlag(prev=>!prev)
    setDoneButton((prev) => !prev);
  };

  const handleFilterState = (name, value, checked, status) => {
    if (status === "clear") {
      setFilterConfig((prev) => {
        const updatedData = prev?.map((filter) =>
          filter?.name === name ? { ...filter, state: [] } : filter
        );
        return updatedData;
      });
      return;
    }
    if (checked) {
      setFilterConfig((prev) => {
        const updatedData = prev?.map((filter) =>
          filter?.name === name
            ? { ...filter, state: [...filter?.state, value] }
            : filter
        );
        return updatedData;
      });
    } else {
      setFilterConfig((prev) => {
        const updatedData = prev?.map((filter) =>
          filter?.name === name
            ? {
                ...filter,
                state: filter.state.filter((res) => res !== value),
              }
            : filter
        );
        return updatedData;
      });
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setFilter((prev) => ({ ...prev, [anchor]: open }));
  };

  const fetchFilters = async () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const token = isAuth;

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Adjust content type as needed
    };
    try {
      const response = await axios.get(
        `${baseUrl}/api/filters?page=viewOffers`,
        {
          headers,
        }
      );
      if (response) {
        const config = response.data;
        let obj = {};
        const data = config.map((res) => {
          if (!obj.hasOwnProperty(res?.name)) {
            obj[res?.name] = [];
          }
          return { ...res, state: [] };
        });

        // setFilterOptions(obj);
        setFilterConfig(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "Offer ID",
      minWidth: 100,
      // flex: 0.5,
      renderCell: (params) => {
        if (!params?.value) {
          return "NA";
        }
        return (
          <Box
            paddingRight="auto"
            marginLeft="20px"
            fontFamily="ABeeZee"
            fontWeight={400}
            fontSize="13px"
            lineHeight="15.37px"
            letterSpacing="2%"
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Offer Name",
      // flex: 1.4,
      minWidth: 250,
      renderCell: (params) => {
        if (!params?.value) {
          return "NA";
        }

        return (
          <BootstrapTooltip title={params.value}>
            <Typography
              fontFamily="Roboto"
              fontWeight={600}
              fontSize="13px"
              lineHeight="15.23px"
              letterSpacing="2%"
              color="#043A87"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Typography>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "game_type",
      headerName: "Game Type",
      minWidth: 150,
      // flex: 0.9,
      renderCell: (params) => {
        if (!params?.value) {
          return "NA";
        }

        return (
          <BootstrapTooltip title={params.value}>
            <Box
              fontFamily="ABeeZee"
              fontWeight={400}
              fontSize="13px"
              lineHeight="15.37px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "reward_currency",
      headerName: "Reward Currency",
      minWidth: 120,
      // flex: 0.8,
      renderCell: (params) => {
        if (!params?.value) {
          return "NA";
        }

        return (
          <Box
            fontFamily="ABeeZee"
            fontWeight={400}
            fontSize="13px"
            lineHeight="15.37px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "offer_status",
      headerName: "Offer Status",
      minWidth: 120,
      // flex: 0.7,
      renderCell: (params) => {
        if (!params?.value) {
          return "NA";
        }

        return (
          <Box
            fontFamily="ABeeZee"
            fontWeight={400}
            fontSize="13px"
            lineHeight="15.37px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "duration",
      headerName: "Duration",
      minWidth: 100,
      // flex: 0.6,
      renderCell: (params) => {
        if (!params?.value) {
          return "NA";
        }

        return (
          <Box
            fontFamily="ABeeZee"
            fontWeight={400}
            fontSize="13px"
            lineHeight="15.37px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    // {
    //   field: "category_id",
    //   headerName: "Category",
    //   minWidth: 100,
    //   // flex: 0.6,
    //   renderCell: (params) => {
    //     if (!params?.value) {
    //       return "NA";
    //     }

    //     return (
    //       <Box
    //         fontFamily="ABeeZee"
    //         fontWeight={400}
    //         fontSize="13px"
    //         lineHeight="15.37px"
    //         letterSpacing="2%"
    //         overflow="hidden"
    //         textOverflow="ellipsis"
    //         whiteSpace={"nowrap"}
    //       >
    //         {params?.value}
    //       </Box>
    //     );
    //   },
    // },
    // {
    //   field: "subcategory_id",
    //   headerName: "Subcategory",
    //   minWidth: 100,
    //   // flex: 0.6,
    //   renderCell: (params) => {
    //     if (!params?.value) {
    //       return "NA";
    //     }

    //     return (
    //       <Box
    //         fontFamily="ABeeZee"
    //         fontWeight={400}
    //         fontSize="13px"
    //         lineHeight="15.37px"
    //         letterSpacing="2%"
    //         overflow="hidden"
    //         textOverflow="ellipsis"
    //         whiteSpace={"nowrap"}
    //       >
    //         {params?.value}
    //       </Box>
    //     );
    //   },
    // },
    // {
    //   field: "merchant_id",
    //   headerName: "Merchant",
    //   minWidth: 100,
    //   // flex: 0.6,
    //   renderCell: (params) => {
    //     if (!params?.value) {
    //       return "NA";
    //     }

    //     return (
    //       <Box
    //         fontFamily="ABeeZee"
    //         fontWeight={400}
    //         fontSize="13px"
    //         lineHeight="15.37px"
    //         letterSpacing="2%"
    //         overflow="hidden"
    //         textOverflow="ellipsis"
    //         whiteSpace={"nowrap"}
    //       >
    //         {params?.value}
    //       </Box>
    //     );
    //   },
    // },
    {
      field: "visibility",
      headerName: "Visibility",
      minWidth: 50,
      // flex: 0.5,
      renderCell: (params) => {
        // if (!params?.value) {
        //   return "NA";
        // }

        return (
          <Box
            // fontFamily="ABeeZee"
            // fontWeight={400}
            // fontSize="13px"
            // lineHeight="15.37px"
            // letterSpacing="2%"
            // overflow="hidden"
            // textOverflow="ellipsis"
            // whiteSpace={"nowrap"}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <CustomSwitch
              checked={params?.value === 1}
              onChange={(e) => handleVisibility(e, params)}
            />
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 150,
      // flex: 1,
      marginLeft: "100px",
      renderCell: (params) => {
        const statusValue = offers?.data?.filter((e) => e.id === params.id)[0]
          ?.offer_status;
        return (
          <Box sx={{ display: "flex", gap: "30px" }}>
            <BootstrapTooltip
              title={
                !permission?.CREATE ? "You don't have permission" : "Copy Offer"
              }
            >
              <Box
                // sx={{
                //   width: "100%",
                //   height: "100%",
                //   display: "grid",
                //   placeItems: "center",
                // }}
                onClick={(e) => handleCopy(e, params)}
              >
                <img
                  style={{ height: "24px", width: "24px" }}
                  src={params?.value?.copyBtn}
                  alt="copy"
                />
              </Box>
            </BootstrapTooltip>

            <BootstrapTooltip title={"Preview"}>
              <Box
                // sx={{
                //   width: "100%",
                //   height: "100%",
                //   display: "grid",
                //   placeItems: "center",
                // }}
                onClick={(e) => handlePreview(e, params)}
              >
                <MdPreview
                  style={{
                    height: "24px",
                    width: "24px",
                    // color: permission?.UPDATE ? "black" : "gray",
                    color: "black",
                  }}
                />
              </Box>
            </BootstrapTooltip>

            <BootstrapTooltip
              title={
                !permission?.UPDATE
                  ? "You don't have permission"
                  : statusValue === "ACTIVE"
                  ? "Pause"
                  : "Play"
              }
            >
              <Box
                // sx={{
                //   width: "100%",
                //   height: "100%",
                //   display: "grid",
                //   placeItems: "center",
                // }}
                onClick={(e) => handleEdit(e, params)}
              >
                {params?.value?.editBtn}
                {/* <img
              onClick={(e) => handleEdit(e, params)}
              style={{ height: "24px", width: "24px" }}
              src={params?.value}
              alt="delete"
            /> */}
              </Box>
            </BootstrapTooltip>
          </Box>
        );
      },
    },
  ];
  console.log(dateFilter, "dateFilter");

  useEffect(() => {
    let debounceTimer;

    if (dateFilter.select !== "CUSTOM") {
      // postFilterData("done");
      if (flag) {
        // Fetch data when flag changes
        getOfferSetFunction("");
        setFlag(false);
      } else {
        // Apply debouncing effect only on search
        debounceTimer = setTimeout(() => {
          getOfferSetFunction("");
        }, 1000);
      }
    }

    // Cleanup function
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [
    // offers?.paginationModel?.page,
    // offers?.paginationModel?.pageSize,
    search,
    // offerStatus,
    // dateFilter.select,
  ]);

  useEffect(() => {
    let debounceTimer;

    if (dateFilter.select !== "CUSTOM") {
      // postFilterData("done");
      if (flag) {
        // Fetch data when flag changes
        // getOfferSetFunction("");
        getOfferSetFunction("", null, true);
        setFlag(false);
      } else {
        // Apply debouncing effect only on search
        debounceTimer = setTimeout(() => {
          // getOfferSetFunction("");
          getOfferSetFunction("", null, true);
        }, 0);
      }
    }

    // Cleanup function
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [
    offers?.paginationModel?.page,
    offers?.paginationModel?.pageSize,
    // search,
    // offerStatus,
    // dateFilter.select,
  ]);

  useEffect(() => {
    let debounceTimer;

    if (dateFilter.select !== "CUSTOM") {
      // postFilterData("done");
      if (flag) {
        // Fetch data when flag changes
        getOfferSetFunction("");
        setFlag(false);
      } else {
        // Apply debouncing effect only on search
        debounceTimer = setTimeout(() => {
          getOfferSetFunction("");
        }, 0);
      }
    }

    // Cleanup function
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [offerStatus, dateFilter.select,activeTab]);

  useEffect(() => {
    let debounceTimer;
    if (
      dateFilter?.select == "CUSTOM" &&
      dateFilter?.from_time &&
      dateFilter?.to_time &&
      typeof dateFilter?.from_time === "string" &&
      typeof dateFilter?.to_time === "string"
    ) {
      // Apply debouncing effect only on search
      debounceTimer = setTimeout(() => {
        getOfferSetFunction("");
      }, 1000);
    }

    // Cleanup function
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [
    // dateFilter?.from_time,
    // dateFilter?.to_time,
    // offers?.paginationModel?.page,
    // offers?.paginationModel?.pageSize,
    search,
    // offerStatus,
  ]);

  useEffect(() => {
    let debounceTimer;
    if (
      dateFilter?.select == "CUSTOM" &&
      dateFilter?.from_time &&
      dateFilter?.to_time &&
      typeof dateFilter?.from_time === "string" &&
      typeof dateFilter?.to_time === "string"
    ) {
      // Apply debouncing effect only on search
      debounceTimer = setTimeout(() => {
        getOfferSetFunction("");
      }, 0);
    }

    // Cleanup function
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [
    // // dateFilter?.from_time,
    // // dateFilter?.to_time,
    // offers?.paginationModel?.page,
    // offers?.paginationModel?.pageSize,
    // // search,
    offerStatus,
    // doneButton,
  ]);
  useEffect(() => {
    let debounceTimer;
    if (
      dateFilter?.select == "CUSTOM" &&
      dateFilter?.from_time &&
      dateFilter?.to_time &&
      typeof dateFilter?.from_time === "string" &&
      typeof dateFilter?.to_time === "string"
    ) {
      // Apply debouncing effect only on search
      debounceTimer = setTimeout(() => {
        getOfferSetFunction("", null, true);
      }, 0);
    }

    // Cleanup function
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [offers?.paginationModel?.page, offers?.paginationModel?.pageSize]);

  useEffect(() => {
    let debounceTimer;
    if (
      dateFilter?.select == "CUSTOM" &&
      dateFilter?.from_time &&
      dateFilter?.to_time &&
      typeof dateFilter?.from_time === "string" &&
      typeof dateFilter?.to_time === "string" &&
      dateFilter?.from_time !== "Invalid Date" &&
      dateFilter?.to_time !== "Invalid Date"
    ) {
      // Apply debouncing effect only on search
      debounceTimer = setTimeout(() => {
        getOfferSetFunction("");
      }, 800);
    }

    // Cleanup function
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [dateFilter?.from_time, dateFilter?.to_time,activeTab]);

  useEffect(() => {
    getOfferSetFunction("done");
  }, [doneButton]);

  useEffect(() => {
    // Step 1: Filter TData columns where flag is true
    let filteredTData = fieldsName?.filter((column) => column.flag === true);

    filteredTData = [
      ...filteredTData,
      { key: "action", displayName: "Action", flag: true },
    ];

    // Step 2: Filter columns where field matches key from filteredTData
    const filteredColumns = columns.filter((column) =>
      filteredTData.some(
        (filteredColumn) => filteredColumn.key === column.field
      )
    );

    const finalColumns = [...filteredColumns];

    setColumnFields(finalColumns);
  }, [fieldsName]);

  useEffect(() => {
    fetchFilters();
  }, []);

  useEffect(() => {
    console.log("date");
    if (startDate && endDate) {
      setDateFilter((prev) => ({
        ...prev,
        from_time: dayjs(new Date(startDate)),
        to_time: dayjs(new Date(endDate)),
      }));
    }
  }, [startDate, endDate]);

  // if (loading) {
  //   return <Loader />;
  // }
  const handlePaginationModel = (value) => {
    if (!value) return;
    setOffers((prev) => ({ ...prev, paginationModel: value }));
  };
  // console.log(offers?.paginationModel?.page)

  return (
    <Box mx="30px">
      <ToastContainer />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginTop="31px"
        marginBottom="20px"
        gap="20px"
        flexWrap="wrap"
      >
        <Box
          sx={{ display: "flex", gap: "20px", justifyContent: "space-between" }}
          // width="750px"
        >
          <Box width="280px">
            {/* <Box > */}

            <SearchActionComponent
              value={search}
              setState={setSearch}
              placeholder={"Search By Name And ID"}
            />
          </Box>
          <CommonDateFilterPanel
            dateFilter={dateFilter}
            filterSelectOptions={filterSelectOptions}
            handleDateFilter={handleDateFilter}
          />
        </Box>

        <Button
          sx={moreFilterButtonStyle}
          startIcon={
            <Avatar
              src={spinnerIcon}
              sx={{
                width: "16px",
                height: "16px",
              }}
            />
          }
          onClick={() => {
            toggleDrawer(anchor, true)();
          }}
          variant={"text"}
        >
          <Typography
            fontFamily="Proxima Nova A"
            lineHeight="17.05px"
            fontSize="14px"
            sx={moreFilterTextStyle}
          >
            {"More Filters"}
          </Typography>
        </Button>
        <BootstrapTooltip
          title={permission?.CREATE ? "" : "You don't have permission"}
          placement="top-start"
        >
          <span>
            <Button
              style={{
                backgroundColor: permission?.CREATE ? "black" : "gray",
                height: "43px",
                // width: "120px",
                padding: "14px 20px 14px 20px",
                borderRadius: "5px",
                color: "#FFF",
                fontFamily: "ABeeZee",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                textTransform: "capitalize",
              }}
              disabled={!permission?.CREATE}
              onClick={() => navigate("/create-offers")}
            >
              Create Offer
            </Button>
          </span>
        </BootstrapTooltip>
        <Box marginLeft="auto">
          <ExportButton
            isDisbaled={offers?.data.length === 0 || !permission?.DOWNLOAD}
            handleExport={() => {
              getOfferSetFunction("download", showFilter);
            }}
            TooltipMessage={
              offers?.data.length === 0
                ? "No Data Found"
                : "You don't have permission"
            }
          />
        </Box>
      </Box>
      <Box sx={{ marginBottom: "12px" }}>
        {showFilter && (
          <FilteredResults options={showFilter} handleRemove={removeFilters} />
        )}
      </Box>
      <Box>
        <Box
          sx={{ display: "flex", gap: "10px", justifyContent: "space-between" }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
            }}
          >
            {totalTab.map((tab, index) => (
              <TableTotalCount
                key={index}
                totalCount={tab.totalCount}
                text={tab.name}
                index={tab.name}
                activeTab={activeTab}
                handleChangeTab={() => setActiveTab(tab.name)}
              />
            ))}

            {/* <TableTotalCount
            totalCount={offers?.totalCount}
            text={"All Offers"}
            // index={"All Offers"}
            // activeTab={activeTab}
            // handleChangeTab={()=>setActiveTab("All Offers")}
          /> */}
          </Box>
          <ToggleColumn
            tableFields={fieldsName}
            handleToggleColumn={handleToggleColumn}
            disabledIndex={1}
          />
        </Box>
        <UiTable
          row={offers?.data || []}
          columns={columnFields || []}
          totalCount={offers?.totalCount}
          paginationModel={offers?.paginationModel}
          setPaginationModel={handlePaginationModel}
          handleRowClick={handleRowClick}
          loading={loading}
        />
      </Box>
      <CustomConfirmationDialog
        open={editData}
        setOpen={setEditData}
        deleteById={handleStatusChangeByID}
        prop={IdRef.current}
        status="delete"
        message={message}
      />

      {/* more Filter */}
      <SwipeableDrawerComponent
        onOpen={toggleDrawer(anchor, true)}
        isOpen={filter.right}
        onClose={toggleDrawer(anchor, false)}
        anchor={anchor}
      >
        <CustomFilter
          filterOptions={filterConfig}
          handleClear={handleClearFilter(initialFilterOptions)}
          handleDoneButton={handleDoneButton}
          handleFilterState={handleFilterState}
          onCLose={toggleDrawer(anchor, false)}
        />
      </SwipeableDrawerComponent>
    </Box>
  );
}
