import { Box, Button, Drawer, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { DeviceFrameset } from "react-device-frameset";
import NewImageUpload from "../../../../UIComponents/NewImageUpload";
import { useDispatch, useSelector } from "react-redux";
import cross from "../../../../assets/close.svg";
import clicked from "../../../../assets/clicked.svg";
import { setAfterPlayChange } from "../../../../Redux/OfferReducer/actionType";

export default function AfterPlayDrawer({
  drawerOpen,
  setDrawerOpen,
  handleDrawerOpen,
  handleDrawerClose,
  handleSelectImage,
  value,
  handleChange,
  handleDeleteImage,
  loading,
}) {
  const dispatch = useDispatch();
  const data = useSelector(
    (store) => store?.OfferReducer?.offerData?.createGame
  );
  const image_option = useSelector(
    (store) =>
      store?.OfferReducer?.offerData?.createGame?.drawerData?.image_option
  );

  const backend_label = useSelector(
    (store) =>
      store?.OfferReducer?.offerData?.createGame?.drawerData?.backend_label
  );

  const gameDataName = useSelector(
    (store) =>
      store?.OfferReducer?.offerData?.createGame?.drawerData?.gameDataName
  );

  const game_type = useSelector(
    (store) => store?.OfferReducer?.offerData?.createGame?.drawerData?.game_type
  );

  const action_type = useSelector(
    (store) =>
      store?.OfferReducer?.offerData?.createGame?.drawerData?.action_type
  );

  const rewardIndex = useSelector(
    (store) =>
      store?.OfferReducer?.offerData?.createGame?.drawerData?.rewardIndex
  );

  return (
    <>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        sx={{
          width: "28%",
          maxWidth: "none",
          // overflow: "visible",
          overflowX: "hidden",
          "& .MuiDrawer-paper": {
            width: "28%",
            // overflow: "visible",
          },
        }}
      >
        <Box sx={{ position: "relative", padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",

              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "16px",
                fontWeight: "600",
                color: "#1D1F2C",
                lineHeight: "19.2px",
              }}
            >
              Select Image
            </Typography>
            <Button onClick={handleDrawerClose} sx={{ zIndex: "1000" }}>
              <img
                style={{ height: "24px", width: "24px" }}
                src={cross}
                alt=""
              />
            </Button>
          </Box>
          <Box>
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "14px",
                fontWeight: "600",
                color: "#222222",
                lineHeight: "16.8px",
                marginTop: "20px",
              }}
            >
              Preview
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "-200px",
              left: "50%",
              transform: "translateX(-50%)",
              //   backgroundColor:"#F9F9FC",
              zIndex: "-1000",
            }}
          >
            <DeviceFrameset
              device="iPhone X"
              color="black"
              zoom="25%"
              style={{
                maxHeight: "100%",
                overflowY: "scroll",
                border: "1px solid #003D86",
              }}
            />
          </Box>
          <Box
            sx={{
              height: "250px",

              marginTop: "270px",
            }}
          >
            <Box>
              <NewImageUpload
                  loader={loading}
                index={rewardIndex}
                compareLink={
                  data?.[game_type]?.[gameDataName]?.[rewardIndex]?.[
                    backend_label
                  ]
                }
                backend_label={backend_label}
                handleSelectImage={handleSelectImage}
                value={
                  data?.[game_type]?.[gameDataName]?.[rewardIndex]?.[
                    `${backend_label}_upload_url`
                  ]
                }
                onChange={(value) =>
                  handleSelectImage(
                    `${backend_label}_upload`,
                    value,
                    rewardIndex,
                    {}
                  )
                }
                maxSize="2MB"
                ratio="1:1"
                //   errorMessage={errorMessage?.coverPhoto}
                //   image={coverPhoto}
                handleImage={(value) => {
                  // dispatch({
                  //   type: action_type,
                  //   payload: {
                  //     additionalFields: {
                  //       // [`${backend_label}`]: value,
                  //       [`${backend_label}_upload`]: value,
                  //       [`${backend_label}_upload_id`]: value,
                  //       [`${backend_label}_upload_url`]: value,
                  //     },
                  //     gameDataName: gameDataName,
                  //   },
                  // });
                  const payload = {
                    gameDataName,
                    backend_label,
                    value,
                    additionalFields: {
                      [`${backend_label}_upload`]: value,
                      [`${backend_label}_upload_id`]: value,
                      [`${backend_label}_upload_url`]: value,
                    },
                    rewardIndex,
                  };
                  dispatch({
                    type: setAfterPlayChange,
                    payload,
                  });
                }}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: "Lato",
                  fontSize: "12px",
                  fontWeight: "700",
                  color: "#1D1F2C",
                  lineHeight: "16px",
                  marginTop: "20px",
                  marginBottom: "8px",
                }}
              >
                Colour presets
              </Typography>
              <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                {image_option?.length > 0 &&
                  image_option?.map((item, index) => (
                    <Box
                      key={index}
                      onClick={() =>
                        handleSelectImage(
                          backend_label,
                          item?.url,
                          rewardIndex,
                          {
                            [`${backend_label}_upload_id`]: item?.id,
                          }
                        )
                      }
                      sx={{
                        border:
                          data?.[game_type]?.[gameDataName]?.[rewardIndex]?.[
                            backend_label
                          ] == item?.url
                            ? "1px solid #003D86"
                            : "1px solid transparent",
                        height: "65px",
                        width: "65px",
                        borderRadius: "6px",
                        position: "relative",
                      }}
                    >
                      <Box>
                        <img
                          src={item?.url}
                          alt=""
                          style={{
                            height: "65px",
                            width: "65px",
                            borderRadius: "6px",
                          }}
                        />
                      </Box>
                      <Box
                        sx={
                          data?.[game_type]?.[gameDataName]?.[rewardIndex]?.[
                            backend_label
                          ] == item?.url && {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            backgroundColor: "rgba(0, 61, 134, 0.5)",
                          }
                        }
                      >
                        <img
                          style={{ height: "22px", width: "22px" }}
                          src={clicked}
                          alt=""
                        />
                      </Box>
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
