import React from "react";
import { Box, MenuItem, Select } from "@mui/material";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { formatDateNew } from "../../utils/formateDate";

const CommonDateFilterPanel = ({
  filterSelectOptions = [],
  handleDateFilter,
  dateFilter,
  disabled
}) => {
  // css
  const containerStyle = {
    width: "320px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // gap: "10px",
    borderRadius: "5px",
    padding: "0px 6px",
    border: "1px solid #D9D9D9",
    boxSizing: "content-box",
    background: "#fff",
    // WebkitBoxPack: "justify",
    // WebkitJustifyContent: "space-between",
  };

  // Inline styles for disabled DatePicker
  const disabledDatePickerStyles = {
    color: "gray", // Change the color of the input field
    // Add any additional styles for the calendar
  };
  return (
    <Box sx={containerStyle}>
      <Select
        displayEmpty
        disabled={disabled}
        sx={{
          height: "38px",
          width:"150px",
          padding: "0",
          margin: "0",
          color: "#6A6A6A",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "17.05px",
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "0",
          },
        }}
        name="select"
        value={dateFilter?.select ?? ""}
        onChange={(e) => {
          const { name, value } = e.target;
          handleDateFilter(name, value);
        }}
      >
        <MenuItem value="" disabled>
          --Select--
        </MenuItem>
        {filterSelectOptions.length > 0 &&
          filterSelectOptions.map((res) => (
            <MenuItem key={res.label} value={res.value}>
              {res.label}
            </MenuItem>
          ))}
      </Select>

      <Box>
        <DatePicker
          range
          rangeHover
          dateSeparator=" ~ "
          disabled={dateFilter?.select !== "CUSTOM"}
          value={[
            dateFilter?.from_time ? new Date(dateFilter.from_time) : "",
            dateFilter?.to_time ? new Date(dateFilter.to_time) : "",
          ]}
          style={{
            fontSize: "16px",
            padding: "10px",
            ...(dateFilter?.select !== "CUSTOM" && disabledDatePickerStyles),
            // textAlign: "center",
            width: "180px",
          }}
          placeholder="Start Date ~ End Date"
          onChange={(value) => {
            if (value) {
              const startDate = value[0] ? formatDateNew(value[0]) : "";
              const endDate = value[1] ? formatDateNew(value[1]) : "";
              handleDateFilter("from_time", startDate);
              handleDateFilter("to_time", endDate);
            }
          }}
          format="DD:MM:YYYY"
          editable={false}
          containerStyle={
            {
              // Add styles for the container element
            }
          }
          inputClass="rmdp-input-centered"
        />
      </Box>

      {/* Add a <style> tag to define the custom CSS */}
      <style>{`
        .rmdp-input-centered::placeholder {
          text-align: center;
          color: inherit;
        }
      `}</style>
    </Box>
  );
};

export default CommonDateFilterPanel;
