import React from "react";
import OfferStepper from "../components/offers/OfferStepper";

const CreateOffers = () => {
  return (
    <div>
      <OfferStepper />
    </div>
  );
};

export default CreateOffers;
