import React, { useRef, memo, useMemo } from "react";
import JoditEditor from "jodit-react";
import { Box } from "@mui/material";

const RichEditor = ({
  placeholder = "",
  onChange,
  value,
  errorMessage,
  editBoolean,
  isDisable,
}) => {
  const editor = useRef(null);

  const config = useMemo(() => {
    return {
      readonly: isDisable, // Disable input based on isDisable prop
      placeholder: placeholder || "",
      height: 300,
      disablePlugins: ["font", "speech-recognize", "add-new-line"],
      editorCssClass: editBoolean ? "orange-outline" : "",
    };
  }, [placeholder, editBoolean, isDisable]);

  return useMemo(
    () => (
      <Box
        sx={{
          mt: "10px",
          width: "100%",
          textAlign: "start",
          mb: "0px",
          "& .jodit-container": {
            border: errorMessage
              ? "1px solid #d40e10"
              : editBoolean
              ? "1px solid orange"
              : "",
            minHeight: "50px !important",
            maxHeight: "200px !important",
            borderRadius: "6px !important", // Added border radius here
            overflow: "hidden", // Added to ensure content doesn't overflow rounded corners
          },
          "& .jodit-wysiwyg": {
            minHeight: "50px !important",
            maxHeight: "100px !important",
          },
          "& .jodit-workplace": {
            minHeight: "50px !important",
            maxHeight: "100px !important",
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            color: "#1D1F2C", // Keep color unchanged
            pointerEvents: isDisable ? "none" : "auto", // Disable interaction without changing visuals
          },
        }}
      >
        <JoditEditor
          ref={editor}
          value={value}
          config={config}
          onChange={isDisable ? undefined : onChange} // Disable onChange when isDisable is true
        />
        {errorMessage && (
          <Box
            sx={{
              mt: 1,
              fontSize: "12px",
              color: "#d74848",
              textAlign: "left",
              ml: "15px",
            }}
          >
            {errorMessage}
          </Box>
        )}
      </Box>
    ),
    [config, value, onChange, errorMessage, editBoolean, isDisable]
  );
};

export default memo(RichEditor);
