import {
  Box,
  Button,
  Checkbox,
  Radio,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editReward,
  selectGameType,
  setAfterPlayChange,
  setDrawerImageOption,
  setRewardArray,
  toggleReward,
} from "../../../../Redux/OfferReducer/actionType";
import editPen from "../../../../assets/Pen.png";
import arrowUp from "../../../../assets/arrow-up.png";
import arrowDown from "../../../../assets/arrow-down.png";
import redDeleteBtn from "../../../../assets/redDeleteBtn.png";
import { getRewardTypesFunction } from "../../../../Redux/OfferReducer/action";
import BoldLabel from "../../../../UIComponents/BoldLevel";
import MuiSelectAndSearch from "../../../../UIComponents/MuiSelectAndSearch";
import UserCohortsFileUpload from "../../../userCohorts/UserCohortsFileUpload";
import MuiInput from "../../../../UIComponents/MuiInput";
import add from "../../../../assets/add.svg";
import RewardDrawer from "./RewardDrawer";
import uploadIcon from "../../../../assets/UploadIcon.svg";
import axios from "axios";
import saveImg from "../../../../assets/saved.svg";
// import BoldLabel from "../../../../UIComponents/BoldLevel";
const schemaWithReward = {
  limit: false,
  reward_type: {
    name: "Neucoins",
    id: "neu_coin",
  },
  isOpen: true,
  rewardLimits: [
    {
      entity_type: "reward_count",
      limit_level: "product",
      daily_limit: "",
      weekly_limit: "",
      monthly_limit: "",
      overall_limit: "",
    },
    {
      entity_type: "reward_value",
      limit_level: "product",
      daily_limit: "",
      weekly_limit: "",
      monthly_limit:"",
      overall_limit: "",
    },
    {
      entity_type: "reward_count",
      limit_level: "user",
      daily_limit: "",
      weekly_limit:"",
      monthly_limit: "",
      overall_limit: "",
    },
    {
      entity_type: "reward_value",
      limit_level: "user",
      daily_limit:"",
      weekly_limit: "",
      monthly_limit: "",
      overall_limit: "",
    },
  ],
};

export default function GameType({ showEdit }) {
  const token = useSelector((store) => store.AuthReducer.token);
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [flag, setFlag] = useState(false);

  const handleDrawerOpen = (event, backend_level, options) => {
    event.stopPropagation();
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const data = useSelector(
    (store) => store?.OfferReducer?.offerData?.createGame
  );
  const fields = useSelector((store) => store?.OfferReducer?.allGames)?.find(
    (e) => e?.name === data?.gameName
  )?.design_fields;

  const gameDataName =
    data?.gameName === "Spin The Wheel"
      ? "spinTheWheelData"
      : data?.gameName === "Slot Machine"
      ? "slotMachineData"
      : data?.gameName === "Scratch Card"
      ? "scratchCardData"
      : data?.gameName === "Trivia"
      ? "triviaData"
      : "";

  const minimumRewardNumber = fields?.minimum_rewards?.value;
  const rewardArray = new Array(minimumRewardNumber).fill(schemaWithReward);

  const rewards = useSelector((store) => store?.OfferReducer?.allRewardTypes);

  console.log(data, "data");
  //   const rewards = useSelector(
  //     (store) => store?.OfferReducer?.allRewards
  //   )?.rewardTypes?.find((item) => item.name === rewardTypeName)?.reward_details;

  //   const handlePrevious = () => {
  //     dispatch({
  //       type: selectGameType,
  //       payload: { gameTypeName: "" },
  //     });
  //   };

  const handleToggleReward = (index) => {
    const updatedRewardArray = [...data.game[gameDataName]];
    updatedRewardArray[index] = {
      ...updatedRewardArray[index],
      isOpen: !updatedRewardArray[index].isOpen,
    };
    dispatch({
      type: toggleReward,
      payload: {
        gameDataName: gameDataName,
        updatedRewardArray: updatedRewardArray,
      },
    });
  };

  const handleEditReward = (field, value, rewardIndex, extraField) => {

    const updatedRewardArray = [...data.game[gameDataName]];
    if (field.endsWith("_upload")) {
      const formData = new FormData();
      formData.append("file", value);
      axios
        .post(`${BaseUrl}/api/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const baseField = field.replace("_upload", "");
          // const payload = {
          //   gameDataName,
          //   field,
          //   value,
          //   additionalFields: {
          //     ...additionalFields,
          //     [`${baseField}_upload_url`]: response.data.public_url,
          //     [`${baseField}_upload_id`]: response.data.id,
          //     [baseField]: response.data.public_url,
          //   },
          //   rewardIndex,
          // };
          // dispatch({ type: setAfterPlayChange, payload });
          updatedRewardArray[rewardIndex] = {
            ...updatedRewardArray[rewardIndex],
            [field]: value,
            [`${baseField}_upload_url`]: response.data.public_url,
            [`${baseField}_upload_id`]: response.data.id,
            [baseField]: response.data.public_url,
          };
          dispatch({
            type: editReward,
            payload: {
              gameDataName: gameDataName,
              updatedRewardArray: updatedRewardArray,
            },
          });
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    } else {
      updatedRewardArray[rewardIndex] = {
        ...updatedRewardArray[rewardIndex],
        [field]: value,
        ...extraField,
      };
      dispatch({
        type: editReward,
        payload: {
          gameDataName: gameDataName,
          updatedRewardArray: updatedRewardArray,
        },
      });
    }
if(field === "win_probability"){

  setFlag(prev=>!prev)
}
    // if(field==="win_probability"){
    // }
  };

  const handleRewardLimitChange = (field, value, rewardIndex, entityType, limitLevel) => {
    const updatedRewardArray = [...data.game[gameDataName]];
    const currentReward = updatedRewardArray[rewardIndex];
  
    // Find the existing limit object or create a new one if it doesn't exist
    let limitObject = currentReward.rewardLimits.find(
      limit => limit.entity_type === entityType && limit.limit_level === limitLevel
    );
  
    if (!limitObject) {
      limitObject = { entity_type: entityType, limit_level: limitLevel };
      currentReward.rewardLimits.push(limitObject);
    }
  
    // Update the specific limit field
    limitObject[field] = value;
  
    // Update the reward in the array
    updatedRewardArray[rewardIndex] = {
      ...currentReward,
      rewardLimits: [...currentReward.rewardLimits]
    };
  
    // Dispatch the update
    dispatch({
      type: editReward,
      payload: {
        gameDataName: gameDataName,
        updatedRewardArray: updatedRewardArray,
      },
    });
  
    setFlag(prev => !prev);
  };

  const handleDeleteReward = (index) => {
    const updatedRewardArray = [...data.game[gameDataName]];
    updatedRewardArray.splice(index, 1);
    dispatch({
      type: setRewardArray,
      payload: {
        gameDataName: gameDataName,
        rewardArray: updatedRewardArray,
      },
    });
    setFlag(prev=>!prev)
  };

  const handleAddReward = () => {
    const updatedRewardArray = [...data.game[gameDataName]];
    updatedRewardArray.push(schemaWithReward);
    dispatch({
      type: setRewardArray,
      payload: {
        gameDataName: gameDataName,
        rewardArray: updatedRewardArray,
      },
    });
  };

  useEffect(() => {
    if (data?.gameName) {
      dispatch({
        type: setRewardArray,
        payload: {
          rewardArray:
            data?.game?.[gameDataName]?.length > 0
              ? data?.game?.[gameDataName]
              : rewardArray,
          gameDataName: gameDataName,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (data?.game?.[gameDataName]?.length > 0) {
      const sum = data.game[gameDataName].reduce(
        (total, reward) => total + (parseFloat(reward.win_probability) || 0),
        0
      );
  
      if (sum === 0) {
        // Handle the case where the sum is zero
        const equalProbability = 100 / data.game[gameDataName].length;
        const updatedRewardArray = data.game[gameDataName].map((reward) => ({
          ...reward,
          win_probability_percentage: equalProbability,
        }));
        console.log(updatedRewardArray, "Sum is zero");
        dispatch({
          type: setRewardArray,
          payload: {
            rewardArray: updatedRewardArray,
            gameDataName: gameDataName,
          },
        });
      } else {
        // Calculate win_probability_percentage
        let updatedRewardArray = data.game[gameDataName].map((reward) => {
          const winProbability = parseFloat(reward.win_probability) || 0;
          const adjustedWinProbability = (winProbability * 100) / sum;
          return {
            ...reward,
            win_probability_percentage: adjustedWinProbability,
          };
        });
  
        // Fix rounding errors to ensure the total sum is exactly 100
        const totalPercentage = updatedRewardArray.reduce(
          (total, reward) => total + reward.win_probability_percentage,
          0
        );
  
        const roundingAdjustment = 100 - totalPercentage;
  
        if (roundingAdjustment !== 0) {
          // Adjust the first reward by the rounding difference to ensure total equals 100
          updatedRewardArray[0].win_probability_percentage += roundingAdjustment;
        }
  
        console.log(updatedRewardArray, "Sum is not zero");
        dispatch({
          type: setRewardArray,
          payload: {
            rewardArray: updatedRewardArray,
            gameDataName: gameDataName,
          },
        });
      }
    }
  }, [flag]);
  
  

  console.log(data, "gameDataName");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <RewardDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
        handleSelectImage={handleEditReward}
      />
      <Box>
        {data?.game?.[gameDataName]?.map((reward, rewardIndex) => (
          <Box
            sx={{
              //   height: reward?.isOpen ? "100%" : "36px",
              border: "1px solid #E4E4F0",
              pl: "20px",
              borderRadius: "6px",
              mb: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "36px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "16px",
                  color: "#1D1F2C",
                }}
              >
                Reward #{rewardIndex + 1}
              </Typography>

              <Box sx={{ display: "flex", gap: "16px" }}>
                {/* <Button sx={{ minWidth: "0px" }}>
                  <img
                    style={{ width: "16px", height: "16px" }}
                    src={editPen}
                    alt=""
                  />
                </Button> */}
                <Button
                  sx={{
                    minWidth: "0px",
                    display:
                      data?.game?.[gameDataName]?.length > minimumRewardNumber && !showEdit
                        ? "flex"
                        : "none",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => handleDeleteReward(rewardIndex)}
                >
                  <img
                    style={{ width: "16px", height: "16px" }}
                    src={redDeleteBtn}
                    alt=""
                  />
                </Button>
                <Button
                  sx={{ minWidth: "0px" }}
                  onClick={() => handleToggleReward(rewardIndex)}
                >
                  {reward?.isOpen ? (
                    <img
                      style={{ width: "16px", height: "16px" }}
                      src={arrowUp}
                      alt=""
                    />
                  ) : (
                    <img
                      style={{ width: "16px", height: "16px" }}
                      src={arrowDown}
                      alt=""
                    />
                  )}
                </Button>
              </Box>
            </Box>
            <Box>
              {console.log(rewards, "reward")}
              <Box>
                {reward?.isOpen && (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "start",
                      // gap: "20px",
                    }}
                  >
                    {rewards
                      ?.find(
                        (rewardDetail) =>
                          rewardDetail?.name == reward?.reward_type?.name
                      )
                      ?.reward_details?.map((item, index) => (
                        <Box
                          // mt={"-15px"}
                          key={`reward-${item.backend_label}-${index}`}
                          // sx={{ display: item?.mandatory ? "block" : "none" }}
                          sx={{
                            width: item?.style?.width
                              ? item?.style?.width
                              : "calc(96% + 20px)",
                            display: item?.mandatory ? "block" : "none",
                            marginRight: "20px",
                          }}
                        >
                          {item?.input_type === "dropdown" ? (
                            <Box>
                              <BoldLabel
                                label={`${index + 1}. ${item.label}`}
                                mt="0px"
                              />
                              <MuiSelectAndSearch
                                isDisable={showEdit}
                                options={item?.options?.map((option) => ({
                                  name: option?.label || option?.name,
                                  id: option?.value || option?.id,
                                  fields: option?.fields,
                                }))}
                                value={
                                  data?.game?.[gameDataName]?.[rewardIndex]?.[
                                    item?.backend_label
                                  ]
                                }
                                onChange={(event) => {
                                  //   handleEditReward(item?.backend_label, event, rewardIndex);
                                  handleEditReward(
                                    item?.backend_label,
                                    event,
                                    rewardIndex
                                  );
                                  //   if (index === 0) {
                                  //     setRewardTypeName(event?.name);
                                  //   }
                                }}
                                placeholder="--Select--"
                              />
                            </Box>
                          ) : item?.input_type === "file_upload" ? (
                            <Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <BoldLabel
                                  label={`${index + 1}. ${item.label}`}
                                  mt="0px"
                                />

                                <Typography
                                  // onClick={handleDownload}
                                  style={{
                                    fontFamily: "Mulish",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    lineHeight: "17.57px",
                                    color: "#0F1828",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                >
                                  Download Sample
                                </Typography>
                              </Box>
                              <UserCohortsFileUpload
                                data={data?.game?.[gameDataName]?.[rewardIndex]}
                                textVisibility={false}
                                onChange={(gameDataName, event) =>
                                  handleEditReward(
                                    gameDataName,
                                    event,
                                    rewardIndex
                                  )
                                }
                                errorMessage={
                                  data?.game?.[gameDataName]?.[rewardIndex]
                                    ?.csvError
                                }
                              />
                            </Box>
                          ) : item?.input_type === "input_box" ? (
                            <Box>
                              <BoldLabel
                             label={`${index + 1}. ${item.label} ${
                              item?.backend_label === "win_probability" && 
                              data?.game?.[gameDataName]?.[rewardIndex]?.win_probability_percentage &&  data?.game?.[gameDataName]?.[rewardIndex]?.[
                                item?.backend_label
                              ]? 
                              `(${data.game[gameDataName][rewardIndex].win_probability_percentage.toFixed(2)}%)` : ""
                            } `}
                                mt="0px"
                              />
                              <MuiInput
                                disable={showEdit}
                                type={item?.type ?? "text"}
                                value={
                                  data?.game?.[gameDataName]?.[rewardIndex]?.[
                                    item?.backend_label
                                  ] || ""
                                }
                                onChange={(event) =>
                                  handleEditReward(
                                    item?.backend_label,
                                    event,
                                    rewardIndex
                                  )
                                }
                                placeholder="40 Characters"
                              />
                            </Box>
                          ) : item?.input_type === "image_upload" ? (
                            <Box>
                              <BoldLabel
                                label={`${index + 1}. ${item.label}`}
                                mt="0px"
                              />
                              {console.log(item, "abcd")}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "start",
                                  gap: "15px",
                                  mt: "10px",
                                }}
                              >
                                <Box
                                  sx={{
                                    borderRadius: "8px",
                                    cursor: "pointer",
                                    padding: "14px 15px",
                                    height: "62px",
                                    backgroundColor: "#F9F9FC",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    gap: "15px",
                                    width: "100%",
                                  }}
                                  onClick={(event) => {
                                    if (showEdit) return;
                                    handleDrawerOpen(event);
                                    dispatch({
                                      type: setDrawerImageOption,
                                      payload: {
                                        rewardIndex: rewardIndex,
                                        backend_label: item?.backend_label,
                                        image_option: item?.options,
                                        gameDataName: gameDataName,
                                        game_type: "game",
                                        action_type: setAfterPlayChange,
                                      },
                                    });
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "15px",
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: "12px",
                                        height: "12px",
                                      }}
                                      src={uploadIcon}
                                      alt=""
                                    />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "start",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        gap: "8px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          textAlign: "start",
                                          fontFamily: "Lato",
                                          fontSize: "12px",
                                          fontStyle: "normal",
                                          fontWeight: 600,
                                          lineHeight: "14.4px",
                                          color: "#003D86",
                                        }}
                                      >
                                        Upload
                                      </Typography>
                                      <Typography
                                        sx={{
                                          textAlign: "start",
                                          color: "#7E8794",
                                          fontFamily: "Lato",
                                          fontSize: "10px",
                                          fontStyle: "normal",
                                          fontWeight: 400,
                                          lineHeight: "12px",
                                        }}
                                      >
                                        Maximum 2MB. Upload .jpeg or .png,
                                        files. Aspect Ratio 1:1
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "8px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        textAlign: "start",
                                        fontFamily: "Lato",
                                        fontSize: "12px",
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        lineHeight: "14.4px",
                                        color: "#003D86",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      Or select from presets
                                    </Typography>
                                    <Box
                                      sx={{
                                        position: "relative",
                                        width: "80px",
                                        height: "30px",
                                      }}
                                    >
                                      {item?.options?.length > 0 &&
                                        item?.options
                                          ?.slice(0, 3)
                                          ?.map((image, index) => (
                                            <img
                                              key={index}
                                              src={image?.url}
                                              alt=""
                                              style={{
                                                width: "30px",
                                                height: "30px",
                                                borderRadius: "4px",
                                                position: "absolute",
                                                left: `${index * 20}px`,
                                                zIndex: index,
                                              }}
                                            />
                                          ))}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          ) : // <Box>
                          //   <BoldLabel label={`${index + 1}. ${item.label}`} />
                          //   <Box
                          //     sx={{
                          //       display: "flex",
                          //       gap: "10px",
                          //       alignItems: "center",
                          //     }}
                          //   >
                          //     <Box
                          //       sx={{
                          //         display: "flex",
                          //         gap: "20px",
                          //         py: "15px",
                          //       }}
                          //     >
                          //       {item?.options?.map((option, optionIndex) => (
                          //         <Box
                          //           key={optionIndex}
                          //           sx={{
                          //             width: "64px",
                          //             height: "64px",
                          //             backgroundColor: "#F4F4F4",
                          //             display: "flex",
                          //             flexDirection: "column",
                          //             justifyContent: "center",
                          //             alignItems: "center",
                          //             gap: "10px",
                          //             border:
                          //               data?.game?.[gameDataName]?.[
                          //                 rewardIndex
                          //               ]?.rewardIcon === option
                          //                 ? "2px solid #000000"
                          //                 : "2px solid transparent",
                          //           }}
                          //           onClick={() =>
                          //             handleEditReward(
                          //               "rewardIcon",
                          //               option,
                          //               rewardIndex
                          //             )
                          //           }
                          //         >
                          //           <img
                          //             style={{
                          //               height: "64px",
                          //               width: "64px",
                          //             }}
                          //             src={option}
                          //             alt=""
                          //           />
                          //         </Box>
                          //       ))}
                          //     </Box>

                          //     <Box
                          //       sx={{
                          //         height: "100px",
                          //         display: "flex",
                          //         alignItems: "center",
                          //         gap: "25px",
                          //       }}
                          //     >
                          //       <Typography>OR</Typography>
                          //       <Box>
                          //         <SmallImageUpload
                          //           value={
                          //             item?.options?.includes(
                          //               data?.game?.[gameDataName]?.[
                          //                 rewardIndex
                          //               ]?.rewardIcon
                          //             )
                          //               ? ""
                          //               : data?.game?.[gameDataName]?.[
                          //                   rewardIndex
                          //                 ]?.rewardIcon
                          //           }
                          //           onClick={() => {
                          //             if (
                          //               data?.game?.[gameDataName]?.[
                          //                 rewardIndex
                          //               ]?.rewardIconUploadUrl
                          //             ) {
                          //               handleEditReward(
                          //                 "rewardIcon",
                          //                 data?.game?.[gameDataName]?.[
                          //                   rewardIndex
                          //                 ]?.rewardIconUploadUrl,
                          //                 rewardIndex
                          //               );
                          //             }
                          //           }}
                          //           borderValue={
                          //             data?.game?.[gameDataName]?.[
                          //               rewardIndex
                          //             ]?.rewardIcon
                          //           }
                          //           onChange={(event) =>
                          //             handleEditReward(
                          //               "rewardIconUpload",
                          //               event,
                          //               rewardIndex
                          //             )
                          //           }
                          //           // handleImage={(value) =>
                          //           //   onChange({
                          //           //     ...data,
                          //           //     game: {
                          //           //       ...data.game,
                          //           //       [gameDataName]: data.game[gameDataName].map((item, index) =>
                          //           //         index === rewardIndex
                          //           //           ? {
                          //           //               ...item,
                          //           //               rewardIconUpload: value,
                          //           //               rewardIconUploadUrl: value,
                          //           //               rewardIconUploadId: value,
                          //           //               rewardIcon: value,
                          //           //             }
                          //           //           : item
                          //           //       ),
                          //           //     },
                          //           //   })
                          //           // }
                          //           image={
                          //             data?.game?.[gameDataName]?.[
                          //               rewardIndex
                          //             ]?.rewardIconUploadUrl
                          //           }
                          //         />
                          //       </Box>
                          //     </Box>
                          //   </Box>
                          // </Box>
                          item?.input_type === "array" ? (
                            <Box>
                              <Box sx={{ display: "flex", gap: "20px" }}>
                                {(() => {
                                  // const fields =
                                  //   data?.addNewRewards?.[gameDataName]?.startFromScratch?.[
                                  //     rewards?.[index - 1]?.backend_label
                                  //   ]?.fields;
                                  // const cashbackTypeName =
                                  //   data?.addNewRewards?.[gameDataName]?.startFromScratch?.[
                                  //     rewards?.[index - 1]?.backend_label
                                  //   ]?.id || "anything";

                                  const fields =
                                    data?.game?.[gameDataName]?.[rewardIndex]?.[
                                      rewards.find(
                                        (rewardDetail) =>
                                          rewardDetail?.name ===
                                          reward?.reward_type?.name
                                      )?.reward_details?.[index - 1]
                                        ?.backend_label
                                    ]?.fields;

                                  const cashbackTypeName =
                                    data?.game?.[gameDataName]?.[rewardIndex]?.[
                                      rewards.find(
                                        (rewardDetail) =>
                                          rewardDetail?.name ===
                                          reward?.reward_type?.name
                                      )?.reward_details?.[index - 1]
                                        ?.backend_label
                                    ]?.id || "anything";

                                  console.log(
                                    cashbackTypeName,
                                    "cashbackTypeName"
                                  );
                                  if (!fields || fields.length === 0) {
                                    return (
                                      <></>
                                      // <Box
                                      //   sx={{
                                      //     width: "100%",
                                      //     maxWidth: "31.3%",
                                      //   }}
                                      // >
                                      //   <BoldLabel
                                      //     label={`${index + 1}. ${item.label}`}
                                      //     mt="0px"
                                      //   />
                                      //   <MuiInput
                                      //     disable={showEdit}
                                      //     type="number"
                                      //     key={`input-${cashbackTypeName}`}
                                      //     value={
                                      //       data?.game?.[gameDataName]?.[
                                      //         rewardIndex
                                      //       ]?.[cashbackTypeName] || ""
                                      //     }
                                      //     onChange={(event) =>
                                      //       handleEditReward(
                                      //         cashbackTypeName,
                                      //         event,
                                      //         rewardIndex
                                      //       )
                                      //     }
                                      //     placeholder="Enter value"
                                      //   />
                                      // </Box>
                                    );
                                  }

                                  return fields.map((fieldItem, fieldIndex) => {
                                    const key = `${cashbackTypeName}_${fieldItem?.backend_label}`;
                                    return (
                                      <Box
                                        key={`gameDataName-${key}-${fieldIndex}`}
                                        sx={{
                                          width: "100%",
                                          maxWidth: "calc(32%)",
                                          // fields.length > 2
                                          //   ? "150px"
                                          //   : fields.length === 2
                                          //   ? "195px"
                                          //   : "400px",
                                        }}
                                      >
                                        <BoldLabel
                                          label={`${index + 1 + fieldIndex}. ${
                                            fieldItem?.label
                                          }`}
                                          // mt="0px"
                                        />
                                        <MuiInput
                                          disable={showEdit}
                                          type="number"
                                          key={`input-${key}`}
                                          value={
                                            data?.game?.[gameDataName]?.[
                                              rewardIndex
                                            ]?.[key] || ""
                                          }
                                          onChange={(event) =>
                                            handleEditReward(
                                              key,
                                              event,
                                              rewardIndex
                                            )
                                          }
                                          placeholder={fieldItem?.label}
                                        />
                                      </Box>
                                    );
                                  });
                                })()}
                              </Box>
                            </Box>
                          ) : item?.input_type === "reward_limit" ? (
                            <Box
                              sx={{
                                // padding: "12px 14px ",
                                // borderRadius: "6px",
                                mb: "8px",
                                mt:"20px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <Switch
                                  sx={{
                                    marginLeft:"-9px",
                                    "& .MuiSwitch-switchBase.Mui-checked": {
                                      color: "#ffffff",
                                    },
                                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                      {
                                        backgroundColor: "#29CC6A",
                                      },
                                  }}
                                  checked={
                                    data?.game?.[gameDataName]?.[rewardIndex]?.[
                                      item?.backend_label
                                    ] ?? false
                                  }
                                  onChange={(event) =>
                                    handleEditReward(
                                      item?.backend_label,
                                      event.target.checked,
                                      rewardIndex
                                    )
                                  }
                                />
                                <Typography
                                  sx={{
                                    fontFamily: "Lato",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    lineHeight: "16px",
                                    color: "#1D1F2C",
                                  }}
                                >
                                  Add Reward Limit
                                </Typography>
                              </Box>

                              {data?.game?.[gameDataName]?.[rewardIndex]?.[
                                item?.backend_label
                              ] && (
                                <Box
                                  sx={{
                                    backgroundColor: "#F9F9FC",
                                    borderRadius: "6px",
                                    padding: data?.game?.[gameDataName]?.[
                                      rewardIndex
                                    ]?.user_level_isOpen
                                      ? "0px 14px 12px 14px "
                                      : "0px 14px",
                                  }}
                                >
                                  <Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        height: "40px",
                                        borderBottom:
                                          data?.game?.[gameDataName]?.[
                                            rewardIndex
                                          ]?.user_level_isOpen &&
                                          "1px solid #E4E4F0",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "12px",
                                        }}
                                      >
                                        {data?.game?.[gameDataName]?.[
                                          rewardIndex
                                        ]?.user_level_isSaved && (
                                          <img src={saveImg} alt="" />
                                        )}
                                        <Typography
                                          sx={{
                                            fontFamily: "Lato",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            lineHeight: "16px",
                                            color: "#1D1F2C",
                                          }}
                                        >
                                          User Level
                                        </Typography>
                                      </Box>
                                      <Box
                                        onClick={() => {
                                          handleEditReward(
                                            "user_level_isOpen",
                                            !data?.game?.[gameDataName]?.[
                                              rewardIndex
                                            ]?.user_level_isOpen,
                                            rewardIndex
                                          );
                                        }}
                                      >
                                        <img
                                          style={{
                                            display: data?.game?.[
                                              gameDataName
                                            ]?.[rewardIndex]?.user_level_isOpen
                                              ? "block"
                                              : "none",
                                            cursor: "pointer",
                                          }}
                                          src={arrowUp}
                                          alt=""
                                        />
                                        <img
                                          style={{
                                            display: data?.game?.[
                                              gameDataName
                                            ]?.[rewardIndex]?.user_level_isOpen
                                              ? "none"
                                              : "block",
                                            cursor: "pointer",
                                          }}
                                          src={arrowDown}
                                          alt=""
                                        />
                                      </Box>
                                    </Box>
                                    {data?.game?.[gameDataName]?.[rewardIndex]
                                      ?.user_level_isOpen && (
                                      <Box>
                                        <Typography
                                          sx={{
                                            fontFamily: "Lato",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            lineHeight: "16px",
                                            color: "#1D1F2C",
                                            textAlign: "left",
                                            marginTop: "16px",
                                          }}
                                        >
                                          Limit on
                                        </Typography>

                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            // justifyContent: "flex-start",
                                            gap: "12px",
                                            mt: "16px",
                                          }}
                                        >
                                          <Checkbox
                                            checked={
                                              data?.game?.[gameDataName]?.[
                                                rewardIndex
                                              ]?.user_level_limit
                                            }
                                            onChange={(event) => {
                                              handleEditReward(
                                                "user_level_limit",
                                                !data?.game?.[gameDataName]?.[
                                                  rewardIndex
                                                ]?.user_level_limit,
                                                rewardIndex
                                              );
                                            }}
                                            sx={{
                                              height: "20px",
                                              width: "20px",
                                              // marginLeft: "-10px",
                                              // border:"1px solid #E4E4F0",
                                              color: "#E4E4F0",
                                              backgroundColor:"#ffffff",
                                              "&.Mui-checked": {
                                                color: "#09AA61",
                                              },
                                              "&:hover": {
                                                backgroundColor:
                                                  "rgba(9, 170, 97, 0.04)",
                                              },
                                            }}
                                          />

                                          <Typography
                                            sx={{
                                              fontFamily: "Lato",
                                              fontSize: "14px",
                                              fontWeight: "600",
                                              lineHeight: "16px",
                                              color: "#1D1F2C",
                                            }}
                                          >
                                            No. of redemption
                                          </Typography>
                                        </Box>
                                        {data?.game?.[gameDataName]?.[
                                          rewardIndex
                                        ]?.user_level_limit && (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              gap: "20px",
                                              width: "100%",
                                              mt: "-12px",
                                              mb: "16px",
                                            }}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <BoldLabel label="Daily" />
                                              <MuiInput
                                                mt="8px"
                                                disable={showEdit}
                                                value={
                                                  data?.game?.[gameDataName]?.[
                                                    rewardIndex
                                                  ]?.rewardLimits.find((e)=>e?.entity_type === "reward_count" && e?.limit_level==="user")
                                                    ?.daily_limit ||
                                                  ""
                                                }
                                                type="number"
                                                // onChange={(event) =>
                                                //   handleEditReward(
                                                //     "daily_limit_user_level_limit",
                                                //     event,
                                                //     rewardIndex
                                                //   )
                                                // }
                                                onChange={(event) =>
                                                  handleRewardLimitChange(
                                                    "daily_limit",
                                                    event,
                                                    rewardIndex,
                                                    "reward_count",
                                                    "user"
                                                  )
                                                }
                                                placeholder="Daily Limit"
                                              />
                                            </Box>
                                            <Box sx={{ width: "100%" }}>
                                              <BoldLabel label="Weekly" />
                                              <MuiInput
                                              disable={showEdit}
                                                mt="8px"
                                                value={
                                                  data?.game?.[gameDataName]?.[
                                                    rewardIndex
                                                  ]?.rewardLimits.find((e)=>e?.entity_type === "reward_count" && e?.limit_level==="user")
                                                    ?.weekly_limit ||
                                                  ""
                                                }
                                                type="number"
                                                onChange={(event) =>
                                                  handleRewardLimitChange(
                                                    "weekly_limit",
                                                    event,
                                                    rewardIndex,
                                                    "reward_count",
                                                    "user"
                                                  )
                                                }
                                                placeholder="Weekly Limit"
                                              />
                                            </Box>
                                            <Box sx={{ width: "100%" }}>
                                              <BoldLabel label="Monthly" />
                                              <MuiInput
                                               disable={showEdit}
                                                mt="8px"
                                                value={
                                                  data?.game?.[gameDataName]?.[
                                                    rewardIndex
                                                  ]?.rewardLimits.find((e)=>e?.entity_type === "reward_count" && e?.limit_level==="user")
                                                    ?.monthly_limit ||
                                                  ""
                                                }
                                                type="number"
                                                onChange={(event) =>
                                                  handleRewardLimitChange(
                                                    "monthly_limit",
                                                    event,
                                                    rewardIndex,
                                                    "reward_count",
                                                    "user"
                                                  )
                                                }
                                                placeholder="Monthly Limit"
                                              />
                                            </Box>
                                            <Box sx={{ width: "100%" }}>
                                              <BoldLabel label="Lifetime" />
                                              <MuiInput
                                               disable={showEdit}
                                                mt="8px"
                                                value={
                                                  data?.game?.[gameDataName]?.[
                                                    rewardIndex
                                                  ]?.rewardLimits.find((e)=>e?.entity_type === "reward_count" && e?.limit_level==="user")
                                                    ?.overall_limit ||
                                                  ""
                                                }
                                                type="number"
                                                onChange={(event) =>
                                                  handleRewardLimitChange(
                                                    "overall_limit",
                                                    event,
                                                    rewardIndex,
                                                    "reward_count",
                                                    "user"
                                                  )
                                                }
                                                placeholder="Lifetime Limit"
                                              />
                                            </Box>
                                          </Box>
                                        )}

                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            // justifyContent: "flex-start",
                                            mt: "10px",
                                            gap: "12px",
                                          }}
                                        >
                                          <Checkbox
                                            checked={
                                              data?.game?.[gameDataName]?.[
                                                rewardIndex
                                              ]?.user_level_value
                                            }
                                            onChange={(event) => {
                                              handleEditReward(
                                                "user_level_value",
                                                !data?.game?.[gameDataName]?.[
                                                  rewardIndex
                                                ]?.user_level_value,
                                                rewardIndex
                                              );
                                            }}
                                            sx={{
                                              height: "20px",
                                              width: "20px",
                                              // marginLeft: "-10px",
                                              color: "#E4E4F0",
                                              backgroundColor:"#ffffff",
                                              "&.Mui-checked": {
                                                color: "#09AA61",
                                              },
                                              "&:hover": {
                                                backgroundColor:
                                                  "rgba(9, 170, 97, 0.04)",
                                              },
                                            }}
                                          />

                                          <Typography
                                            sx={{
                                              fontFamily: "Lato",
                                              fontSize: "14px",
                                              fontWeight: "600",
                                              lineHeight: "16px",
                                              color: "#1D1F2C",
                                            }}
                                          >
                                            Value
                                          </Typography>
                                        </Box>

                                        {data?.game?.[gameDataName]?.[
                                          rewardIndex
                                        ]?.user_level_value && (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              gap: "20px",
                                              width: "100%",
                                              mt: "-12px",
                                            }}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <BoldLabel label="Daily" />
                                              <MuiInput
                                               disable={showEdit}
                                                mt="8px"
                                                value={
                                                  data?.game?.[gameDataName]?.[
                                                    rewardIndex
                                                  ]?.rewardLimits.find((e)=>e?.entity_type === "reward_value" && e?.limit_level==="user")
                                                    ?.daily_limit ||
                                                  ""
                                                }
                                                type="number"
                                                // onChange={(event) =>
                                                //   handleEditReward(
                                                //     "daily_limit_user_level_value",
                                                //     event,
                                                //     rewardIndex
                                                //   )
                                                // }
                                                onChange={(event) =>
                                                  handleRewardLimitChange(
                                                    "daily_limit",
                                                    event,
                                                    rewardIndex,
                                                    "reward_value",
                                                    "user"
                                                  )
                                                }
                                                placeholder="Daily Limit"
                                              />
                                            </Box>
                                            <Box sx={{ width: "100%" }}>
                                              <BoldLabel label="Weekly" />
                                              <MuiInput
                                               disable={showEdit}
                                               value={
                                                data?.game?.[gameDataName]?.[
                                                  rewardIndex
                                                ]?.rewardLimits.find((e)=>e?.entity_type === "reward_value" && e?.limit_level==="user")
                                                  ?.weekly_limit ||
                                                ""
                                              }
                                                type="number"
                                                onChange={(event) =>
                                                  handleRewardLimitChange(
                                                    "weekly_limit",
                                                    event,
                                                    rewardIndex,
                                                    "reward_value",
                                                    "user"
                                                  )
                                                }
                                                placeholder="Weekly Limit"
                                              />
                                            </Box>
                                            <Box sx={{ width: "100%" }}>
                                              <BoldLabel label="Monthly" />
                                              <MuiInput
                                               disable={showEdit}
                                                mt="8px"
                                                 value={
                                                  data?.game?.[gameDataName]?.[
                                                    rewardIndex
                                                  ]?.rewardLimits.find((e)=>e?.entity_type === "reward_value" && e?.limit_level==="user")
                                                    ?.monthly_limit ||
                                                  ""
                                                }
                                                type="number"
                                                onChange={(event) =>
                                                  handleRewardLimitChange(
                                                    "monthly_limit",
                                                    event,
                                                    rewardIndex,
                                                    "reward_value",
                                                    "user"
                                                  )
                                                }
                                                placeholder="Monthly Limit"
                                              />
                                            </Box>
                                            <Box sx={{ width: "100%" }}>
                                              <BoldLabel label="Lifetime" />
                                              <MuiInput
                                               disable={showEdit}
                                                mt="8px"
                                                value={
                                                  data?.game?.[gameDataName]?.[
                                                    rewardIndex
                                                  ]?.rewardLimits.find((e)=>e?.entity_type === "reward_value" && e?.limit_level==="user")
                                                    ?.overall_limit ||
                                                  ""
                                                }
                                                type="number"
                                                onChange={(event) =>
                                                  handleRewardLimitChange(
                                                    "overall_limit",
                                                    event,
                                                    rewardIndex,
                                                    "reward_value",
                                                    "user"
                                                  )
                                                }
                                                placeholder="Lifetime Limit"
                                              />
                                            </Box>
                                          </Box>
                                        )}
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            mt: "16px",
                                          }}
                                        >
                                          <Button
                                            sx={{
                                              height: "24px",
                                              width: "136px",
                                              borderRadius: "6px",
                                              backgroundColor: "#003D86",
                                              color: "#fff",
                                              fontFamily: "Lato",
                                              fontSize: "12px",
                                              fontWeight: "500",
                                              lineHeight: "14.4px",
                                              textTransform: "capitalize",
                                              "&:hover": {
                                                backgroundColor: "#003D86",
                                                color: "#fff",
                                              },
                                            }}
                                            onClick={() => {
                                              handleEditReward(
                                                "user_level_isSaved",
                                                true,
                                                rewardIndex,
                                                {
                                                  user_level_isOpen:
                                                    !data?.game?.[
                                                      gameDataName
                                                    ]?.[rewardIndex]
                                                      ?.user_level_isOpen,
                                                }
                                              );
                                            }}
                                          >
                                            Save
                                          </Button>
                                        </Box>
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                              )}

                              {/* product level */}
                              {data?.game?.[gameDataName]?.[rewardIndex]?.[
                                item?.backend_label
                              ] && (
                                <Box
                                  sx={{
                                    mt: "16px",
                                    backgroundColor: "#F9F9FC",
                                    borderRadius: "6px",
                                    padding: data?.game?.[gameDataName]?.[
                                      rewardIndex
                                    ]?.product_level_isOpen
                                      ? "0px 14px 12px 14px "
                                      : "0px 14px",
                                  }}
                                >
                                  <Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        height: "40px",
                                        borderBottom:
                                          data?.game?.[gameDataName]?.[
                                            rewardIndex
                                          ]?.product_level_isOpen &&
                                          "1px solid #E4E4F0",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "12px",
                                        }}
                                      >
                                        {data?.game?.[gameDataName]?.[
                                          rewardIndex
                                        ]?.product_level_isSaved && (
                                          <img src={saveImg} alt="" />
                                        )}
                                        <Typography
                                          sx={{
                                            fontFamily: "Lato",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            lineHeight: "16px",
                                            color: "#1D1F2C",
                                          }}
                                        >
                                          Offer Level
                                        </Typography>
                                      </Box>
                                      <Box
                                        onClick={() => {
                                          handleEditReward(
                                            "product_level_isOpen",
                                            !data?.game?.[gameDataName]?.[
                                              rewardIndex
                                            ]?.product_level_isOpen,
                                            rewardIndex
                                          );
                                        }}
                                      >
                                        <img
                                          style={{
                                            display: data?.game?.[
                                              gameDataName
                                            ]?.[rewardIndex]
                                              ?.product_level_isOpen
                                              ? "block"
                                              : "none",
                                            cursor: "pointer",
                                          }}
                                          src={arrowUp}
                                          alt=""
                                        />
                                        <img
                                          style={{
                                            display: data?.game?.[
                                              gameDataName
                                            ]?.[rewardIndex]
                                              ?.product_level_isOpen
                                              ? "none"
                                              : "block",
                                            cursor: "pointer",
                                          }}
                                          src={arrowDown}
                                          alt=""
                                        />
                                      </Box>
                                    </Box>
                                    {data?.game?.[gameDataName]?.[rewardIndex]
                                      ?.product_level_isOpen && (
                                      <Box>
                                        <Typography
                                          sx={{
                                            fontFamily: "Lato",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            lineHeight: "16px",
                                            color: "#1D1F2C",
                                            textAlign: "left",
                                            marginTop: "16px",
                                          }}
                                        >
                                          Limit on
                                        </Typography>

                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            // justifyContent: "flex-start",
                                            gap: "12px",
                                            mt: "16px",
                                          }}
                                        >
                                          <Checkbox
                                            checked={
                                              data?.game?.[gameDataName]?.[
                                                rewardIndex
                                              ]?.product_level_limit
                                            }
                                            onChange={(event) => {
                                              handleEditReward(
                                                "product_level_limit",
                                                !data?.game?.[gameDataName]?.[
                                                  rewardIndex
                                                ]?.product_level_limit,
                                                rewardIndex
                                              );
                                            }}
                                            sx={{
                                              height: "20px",
                                              width: "20px",
                                              color: "#E4E4F0",
                                              backgroundColor:"#ffffff",
                                              "&.Mui-checked": {
                                                color: "#09AA61",
                                              },
                                              "&:hover": {
                                                backgroundColor:
                                                  "rgba(9, 170, 97, 0.04)",
                                              },
                                            }}
                                          />

                                          <Typography
                                            sx={{
                                              fontFamily: "Lato",
                                              fontSize: "14px",
                                              fontWeight: "600",
                                              lineHeight: "16px",
                                              color: "#1D1F2C",
                                            }}
                                          >
                                            No. of redemption
                                          </Typography>
                                        </Box>
                                        {data?.game?.[gameDataName]?.[
                                          rewardIndex
                                        ]?.product_level_limit && (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              gap: "20px",
                                              width: "100%",
                                              mt: "-12px",
                                              mb: "16px",
                                            }}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <BoldLabel label="Daily" />
                                              <MuiInput
                                                mt="8px"
                                                value={
                                                  data?.game?.[gameDataName]?.[
                                                    rewardIndex
                                                  ]?.rewardLimits.find((e)=>e?.entity_type === "reward_count" && e?.limit_level==="product")
                                                    ?.daily_limit ||
                                                  ""
                                                }
                                                type="number"
                                                // onChange={(event) =>
                                                //   handleEditReward(
                                                //     "daily_limit_product_level_limit",
                                                //     event,
                                                //     rewardIndex
                                                //   )
                                                // }
                                                onChange={(event) =>
                                                  handleRewardLimitChange(
                                                    "daily_limit",
                                                    event,
                                                    rewardIndex,
                                                    "reward_count",
                                                    "product"
                                                  )
                                                }
                                                placeholder="Daily Limit"
                                              />
                                            </Box>
                                            <Box sx={{ width: "100%" }}>
                                              <BoldLabel label="Weekly" />
                                              <MuiInput
                                                mt="8px"
                                                value={
                                                  data?.game?.[gameDataName]?.[
                                                    rewardIndex
                                                  ]?.rewardLimits.find((e)=>e?.entity_type === "reward_count" && e?.limit_level==="product")
                                                    ?.weekly_limit ||
                                                  ""
                                                }
                                                type="number"
                                                onChange={(event) =>
                                                  handleRewardLimitChange(
                                                    "weekly_limit",
                                                    event,
                                                    rewardIndex,
                                                    "reward_count",
                                                    "product"
                                                  )
                                                }
                                                placeholder="Weekly Limit"
                                              />
                                            </Box>
                                            <Box sx={{ width: "100%" }}>
                                              <BoldLabel label="Monthly" />
                                              <MuiInput
                                                mt="8px"
                                                value={
                                                  data?.game?.[gameDataName]?.[
                                                    rewardIndex
                                                  ]?.rewardLimits.find((e)=>e?.entity_type === "reward_count" && e?.limit_level==="product")
                                                    ?.monthly_limit ||
                                                  ""
                                                }
                                                type="number"
                                                onChange={(event) =>
                                                  handleRewardLimitChange(
                                                    "monthly_limit",
                                                    event,
                                                    rewardIndex,
                                                    "reward_count",
                                                    "product"
                                                  )
                                                }
                                                placeholder="Monthly Limit"
                                              />
                                            </Box>
                                            <Box sx={{ width: "100%" }}>
                                              <BoldLabel label="Lifetime" />
                                              <MuiInput
                                                mt="8px"
                                                value={
                                                  data?.game?.[gameDataName]?.[
                                                    rewardIndex
                                                  ]?.rewardLimits.find((e)=>e?.entity_type === "reward_count" && e?.limit_level==="product")
                                                    ?.overall_limit ||
                                                  ""
                                                }
                                                type="number"
                                                onChange={(event) =>
                                                  handleRewardLimitChange(
                                                    "overall_limit",
                                                    event,
                                                    rewardIndex,
                                                    "reward_count",
                                                    "product"
                                                  )
                                                }
                                                placeholder="Lifetime Limit"
                                              />
                                            </Box>
                                          </Box>
                                        )}

                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            // justifyContent: "flex-start",
                                            mt: "10px",
                                            gap: "12px",
                                          }}
                                        >
                                          <Checkbox
                                            checked={
                                              data?.game?.[gameDataName]?.[
                                                rewardIndex
                                              ]?.product_level_value
                                            }
                                            onChange={(event) => {
                                              handleEditReward(
                                                "product_level_value",
                                                !data?.game?.[gameDataName]?.[
                                                  rewardIndex
                                                ]?.product_level_value,
                                                rewardIndex
                                              );
                                            }}
                                            sx={{
                                              height: "20px",
                                              width: "20px",
                                              color: "#E4E4F0",
                                              backgroundColor:"#ffffff",
                                              "&.Mui-checked": {
                                                color: "#09AA61",
                                              },
                                              "&:hover": {
                                                backgroundColor:
                                                  "rgba(9, 170, 97, 0.04)",
                                              },
                                            }}
                                          />

                                          <Typography
                                            sx={{
                                              fontFamily: "Lato",
                                              fontSize: "14px",
                                              fontWeight: "600",
                                              lineHeight: "16px",
                                              color: "#1D1F2C",
                                            }}
                                          >
                                            Value
                                          </Typography>
                                        </Box>

                                        {data?.game?.[gameDataName]?.[
                                          rewardIndex
                                        ]?.product_level_value && (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              gap: "20px",
                                              width: "100%",
                                              mt: "-12px",
                                            }}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <BoldLabel label="Daily" />
                                              <MuiInput
                                                mt="8px"
                                                value={
                                                  data?.game?.[gameDataName]?.[
                                                    rewardIndex
                                                  ]?.rewardLimits.find((e)=>e?.entity_type === "reward_value" && e?.limit_level==="product")
                                                    ?.daily_limit ||
                                                  ""
                                                }
                                                type="number"
                                                onChange={(event) =>
                                                  handleRewardLimitChange(
                                                    "daily_limit",
                                                    event,
                                                    rewardIndex,
                                                    "reward_value",
                                                    "product"
                                                  )
                                                }
                                                placeholder="Daily Limit"
                                              />
                                            </Box>
                                            <Box sx={{ width: "100%" }}>
                                              <BoldLabel label="Weekly" />
                                              <MuiInput
                                                mt="8px"
                                                value={
                                                  data?.game?.[gameDataName]?.[
                                                    rewardIndex
                                                  ]?.rewardLimits.find((e)=>e?.entity_type === "reward_value" && e?.limit_level==="product")
                                                    ?.weekly_limit ||
                                                  ""
                                                }
                                                type="number"
                                                onChange={(event) =>
                                                  handleRewardLimitChange(
                                                    "weekly_limit",
                                                    event,
                                                    rewardIndex,
                                                    "reward_value",
                                                    "product"
                                                  )
                                                }
                                                placeholder="Weekly Limit"
                                              />
                                            </Box>
                                            <Box sx={{ width: "100%" }}>
                                              <BoldLabel label="Monthly" />
                                              <MuiInput
                                                mt="8px"
                                                value={
                                                  data?.game?.[gameDataName]?.[
                                                    rewardIndex
                                                  ]?.rewardLimits.find((e)=>e?.entity_type === "reward_value" && e?.limit_level==="product")
                                                    ?.monthly_limit ||
                                                  ""
                                                }
                                                type="number"
                                                onChange={(event) =>
                                                  handleRewardLimitChange(
                                                    "monthly_limit",
                                                    event,
                                                    rewardIndex,
                                                    "reward_value",
                                                    "product"
                                                  )
                                                }
                                                placeholder="Monthly Limit"
                                              />
                                            </Box>
                                            <Box sx={{ width: "100%" }}>
                                              <BoldLabel label="Lifetime" />
                                              <MuiInput
                                                mt="8px"
                                                value={
                                                  data?.game?.[gameDataName]?.[
                                                    rewardIndex
                                                  ]?.rewardLimits.find((e)=>e?.entity_type === "reward_value" && e?.limit_level==="product")
                                                    ?.overall_limit ||
                                                  ""
                                                }
                                                type="number"
                                                onChange={(event) =>
                                                  handleRewardLimitChange(
                                                    "overall_limit",
                                                    event,
                                                    rewardIndex,
                                                    "reward_value",
                                                    "product"
                                                  )
                                                }
                                                placeholder="Lifetime Limit"
                                              />
                                            </Box>
                                          </Box>
                                        )}
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            mt: "16px",
                                          }}
                                        >
                                          <Button
                                            sx={{
                                              height: "24px",
                                              width: "136px",
                                              borderRadius: "6px",
                                              backgroundColor: "#003D86",
                                              color: "#fff",
                                              fontFamily: "Lato",
                                              fontSize: "12px",
                                              fontWeight: "500",
                                              lineHeight: "14.4px",
                                              textTransform: "capitalize",
                                              "&:hover": {
                                                backgroundColor: "#003D86",
                                                color: "#fff",
                                              },
                                            }}
                                            onClick={() => {
                                              handleEditReward(
                                                "product_level_isSaved",
                                                true,
                                                rewardIndex,
                                                {
                                                  product_level_isOpen:
                                                    !data?.game?.[
                                                      gameDataName
                                                    ]?.[rewardIndex]
                                                      ?.product_level_isOpen,
                                                }
                                              );
                                            }}
                                          >
                                            Save
                                          </Button>
                                        </Box>
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          ) : (
                            <BoldLabel label={`${index + 1}. ${item.label}`} />
                          )}
                        </Box>
                      ))}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        ))}
        <Button
          sx={{
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "16.8px",
            color: "#003D86",
            float: "right",
            textTransform: "capitalize",
            display:showEdit?"none":"",
          }}
          onClick={handleAddReward}
        >
          <img src={add} alt="" /> &nbsp; Add Reward
        </Button>
      </Box>
    </Box>
  );
}
