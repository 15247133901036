import { TextField, Box, styled } from "@mui/material";
import { DateTimePicker, LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker } from 'antd';
import { BorderColor } from "@mui/icons-material";
// Example index.js or index.jsx
// import 'antd/dist/antd.css';
const StyledDatePicker = styled(DatePicker)(({ theme, errorMessage, editBoolean }) => ({
  '& .ant-picker-input > input': {
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    color: '#151515',
    padding: '0px', // Adjust padding to fit the height of 32px
    height: '23px', // Set height to 32px
    '&::placeholder': {
      fontFamily: 'Lato',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
      color: "#80858f",
      opacity: 0.6,
    },
  },
  '& .ant-picker': {
    borderRadius: '6px', // Set border radius to 6px
    // height: '2px', // Set height to 32px
    border: `1px solid ${editBoolean ? 'orange' : 'rgba(0, 0, 0, 0.59)'}`,
    '&:focus, &.ant-picker-focused': {
      borderColor: editBoolean ? 'orange' : theme.palette.primary.main,
      borderWidth: '2px',
      outline: 'none',
      boxShadow: `0 0 0 2px ${editBoolean ? 'orange' : theme.palette.primary.main}33`,
    },
    // '&.ant-picker-disabled': {
    //   backgroundColor: 'white', // Keep background white when disabled
    //   borderColor: editBoolean ? 'orange' : 'rgba(0, 0, 0, 0.59)', // Maintain border color when disabled
    //   // cursor: 'not-allowed',
    // },
  },
  '&.ant-picker-outlined': {
    backgroundColor: 'white', // Set background color to white
    borderRadius: '6px !important', // Set border radius to 6px
    borderColor: editBoolean ? 'orange' : '#cac9c9',
    '&:hover': {
      borderColor: editBoolean ? 'orange' : `${theme.palette.text.secondary}`,
      borderWidth: '1px',
      borderStyle: 'solid',
    },
  },
  '& .ant-picker-suffix': {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  '& .ant-picker-clear': {
    color: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
}));


export const MUIDateTimePicker = ({
  value,
  onChange,
  disableFuture = false,
  disablePast = false,
  maxDate = null,
  minDate = null,
  errorMessage = "",
  disabled = false,
  placeholder = "Select Date & Time",
  useAntDesign = true, // Add a prop to toggle between MUI and Ant Design
  editBoolean=true,
}) => {
  if (useAntDesign) {
    return (
      <Box sx={{ mt: "10px", width: "100%" }}>
      <StyledDatePicker
      needConfirm={true}
        errorMessage={errorMessage}
        disabled={disabled}
        status={errorMessage ? "error" : undefined}
        value={value ? dayjs(value) : null}
        disabledDate={(current) => {
          return (
            current && (current < dayjs(minDate) || current > dayjs(maxDate))
          );
        }}
        format="DD/MM/YYYY HH:mm"
        showTime={{ format: "HH:mm" }}
        onChange={(date, dateString) => {
          onChange(date);
        }}
        placeholder={placeholder}
        allowClear={false}
        editBoolean={editBoolean}
        style={{ width: "100%" }}
      />
      {errorMessage && (
        <Box sx={{ color: 'error.main', fontSize: '14px', mt: 0.5 ,textAlign:"start",paddingLeft:"10px"}}>
          {errorMessage}
        </Box>
      )}
    </Box>
    );
  } else {
    return (
      <Box sx={{ mt: '10px', width: "100%" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            disabled={disabled}
            value={value ? dayjs(value) : null}
            disablePast={disablePast}
            disableFuture={disableFuture}
            maxDate={maxDate}
            minDate={minDate}
            format="DD/MM/YYYY HH:mm"
            className="form-control"
            slotProps={{
              textField: {
                size: "small",
                error: !!errorMessage,
                helperText: errorMessage,
                label: placeholder,
              },
            }}
            onChange={(dateTime) => {
              if (dateTime?.isValid()) {
                onChange(dateTime?.toDate());
              } else {
                onChange(dateTime);
              }
            }}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
          />
        </LocalizationProvider>
      </Box>
    );
  }
};
