import { Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { CreateGame_PreData } from "../../../../utils/CreateGame_PreData";
import { useDispatch, useSelector } from "react-redux";
import {
  setAfterPlayChange,
  setAfterPlayReward,
  setDrawerImageOption,
} from "../../../../Redux/OfferReducer/actionType";
import RightDrawer from "./RightDrawer";
import BoldLabel from "../../../../UIComponents/BoldLevel";
import MuiSelectAndSearch from "../../../../UIComponents/MuiSelectAndSearch";
import MuiInput from "../../../../UIComponents/MuiInput";
import ColorInput from "../../../../UIComponents/ColorInput";
import axios from "axios";
import uploadIcon from "../../../../assets/UploadIcon.svg";
import AfterPlayDrawer from "./AfterPlayDrawer";
import arrowUp from "../../../../assets/arrow-up.png";
import arrowDown from "../../../../assets/arrow-down.png";
import savedIcon from "../../../../assets/saved.svg";

export default function AfterPlayPage({ showEdit }) {
  const { ctaColor, color } = CreateGame_PreData();
  const [loading, setLoading] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const token = useSelector((store) => store.AuthReducer.token);
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const dispatch = useDispatch();

  const handleDrawerOpen = (event, backend_level, options) => {
    event.stopPropagation();
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const allReward = useSelector(
    (store) => store?.OfferReducer?.allRewards
  )?.rewardTypes;

  const data = useSelector(
    (store) => store?.OfferReducer?.offerData?.createGame
  );
  console.log(data, "data");
  const gameDataName =
    data?.gameName === "Spin The Wheel"
      ? "spinTheWheelData"
      : data?.gameName === "Slot Machine"
      ? "slotMachineData"
      : data?.gameName === "Scratch Card"
      ? "scratchCardData"
      : data?.gameName === "Trivia"
      ? "triviaData"
      : "";

  const uniqueRewards = data?.game?.[gameDataName]?.reduce((acc, current) => {
    const reward = acc.find(
      (item) => item.reward_type.id === current.reward_type.id
    );
    if (!reward) {
      acc.push(current);
    }
    return acc;
  }, []);
  console.log(allReward, uniqueRewards, "allReward");

  const handleAfterPlayChange = (
    field,
    value,
    rewardIndex,
    additionalFields = {}
  ) => {
    if (field.endsWith("_upload")) {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", value);
      axios
        .post(`${BaseUrl}/api/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const baseField = field.replace("_upload", "");
          const payload = {
            gameDataName,
            field,
            value,
            additionalFields: {
              ...additionalFields,
              [`${baseField}_upload_url`]: response.data.public_url,
              [`${baseField}_upload_id`]: response.data.id,
              [baseField]: response.data.public_url,
            },
            rewardIndex,
          };
          dispatch({ type: setAfterPlayChange, payload });
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        }).finally(()=>{
          setLoading(false)
        })
    } else {
      const payload = {
        gameDataName,
        field,
        value,
        additionalFields,
        rewardIndex,
      };
      dispatch({ type: setAfterPlayChange, payload });
    }
  };

  useEffect(() => {
    dispatch({
      type: setAfterPlayReward,
      payload: {
        gameDataName,
        rewardArray:
          data?.afterPlay?.[gameDataName]?.length > 0 &&
          data?.afterPlay?.[gameDataName]?.length === uniqueRewards?.length
            ? data?.afterPlay?.[gameDataName]
            : uniqueRewards,
      },
    });
  }, [uniqueRewards?.length]);

  return (
    <Box>
      <AfterPlayDrawer
      loading={loading}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
        handleSelectImage={handleAfterPlayChange}
      />
      <Box>
        {data?.afterPlay?.[gameDataName]?.map((uniqueReward, rewardIndex) => {
          const matchingReward = allReward?.find(
            (reward) => reward.name === uniqueReward.reward_type.name
          );
          if (!matchingReward) return null;
          let num = 1;
          return (
            <Box
              key={uniqueReward.reward_type.id}
              sx={{
                marginBottom: "20px",
                border: "1px solid #E6E6E6",
                paddingX: "14px",
                borderRadius: "8px",
                pb: uniqueReward?.isOpen ? "10px" : "0px",
                // height: uniqueReward?.isOpen ? "auto" : "15px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "36px",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "12px" }}
                >
                  {uniqueReward?.isSaved && <img src={savedIcon} alt="" />}
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#1D1F2C",
                      // marginBottom: "10px",
                    }}
                  >
                    {uniqueReward.reward_type.name}
                  </Typography>
                </Box>
                <Button
                  sx={{
                    cursor: "pointer",
                    minWidth: "0px",
                  }}
                  onClick={() =>
                    handleAfterPlayChange(
                      "isOpen",
                      !uniqueReward?.isOpen,
                      rewardIndex,
                      {}
                    )
                  }
                >
                  {uniqueReward?.isOpen ? (
                    <img
                      style={{ width: "16px", height: "16px" }}
                      src={arrowDown}
                      alt=""
                    />
                  ) : (
                    <img
                      style={{ width: "16px", height: "16px" }}
                      src={arrowUp}
                      alt=""
                    />
                  )}
                </Button>
              </Box>

              {uniqueReward?.isOpen && (
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#003D86",
                      marginBottom: "10px",
                      my: "16px",
                      textAlign: "left",
                    }}
                  >
                    Design
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "start",
                      gap: "20px",
                    }}
                  >
                    {matchingReward?.design_details?.length > 0 &&
                      matchingReward?.design_details.map((field, index) => {
                        if (!field?.mandatory) return null;
                        const labelNum = num++;
                        return (
                          <Box
                            key={field?.backend_label}
                            sx={{
                              width: field?.style?.width
                                ? field?.style?.width
                                : "calc(96% + 20px)",
                            }}
                          >
                            <BoldLabel
                              label={`${labelNum}. ${field?.label}`}
                              mt="0px"
                            />
                            {field?.input_type === "dropdown" ? (
                              <Box>
                                <MuiSelectAndSearch
                                  isDisable={showEdit}
                                  options={field?.options}
                                  value={
                                    data?.afterPlay?.[gameDataName]?.[
                                      rewardIndex
                                    ]?.[field?.backend_label] || ""
                                  }
                                  onChange={(event) =>
                                    handleAfterPlayChange(
                                      field.backend_label,
                                      event,
                                      rewardIndex,
                                      {}
                                    )
                                  }
                                  placeholder="--Select--"
                                />
                              </Box>
                            ) : field?.input_type === "input_box" ? (
                              <Box>
                                <MuiInput
                                  maxLength={field?.max_characters}
                                  disable={showEdit}
                                  value={
                                    data?.afterPlay?.[gameDataName]?.[
                                      rewardIndex
                                    ]?.[field?.backend_label] || ""
                                  }
                                  placeholder={field?.placeholder}
                                  onChange={(event) =>
                                    handleAfterPlayChange(
                                      field.backend_label,
                                      event,
                                      rewardIndex,
                                      {}
                                    )
                                  }
                                />
                              </Box>
                            ) : field?.input_type === "color_picker" ? (
                              <Box>
                                <ColorInput
                                  preFill={field?.default}
                                  disable={showEdit}
                                  value={
                                    data?.afterPlay?.[gameDataName]?.[
                                      rewardIndex
                                    ]?.[field?.backend_label] || field?.default
                                  }
                                  onChange={(color) =>
                                    handleAfterPlayChange(
                                      field?.backend_label,
                                      color,
                                      rewardIndex,
                                      {}
                                    )
                                  }
                                />
                              </Box>
                            ) : field?.input_type ===
                              "image_and_color_picker" ? (
                              <Box>
                                <Box mb={"16px"}>
                                  <MuiSelectAndSearch
                                    isDisable={showEdit}
                                    options={field?.options?.map((option) => ({
                                      name: option?.label || option?.name,
                                      id: option?.value || option?.id,
                                      options: option?.options,
                                      backend_label: option?.backend_label,
                                    }))}
                                    value={
                                      data?.afterPlay?.[gameDataName]?.[
                                        rewardIndex
                                      ]?.[field?.backend_label]
                                    }
                                    onChange={(value) =>
                                      handleAfterPlayChange(
                                        field?.backend_label,
                                        value,
                                        rewardIndex,
                                        {}
                                      )
                                    }
                                    placeholder="--Select--"
                                  />
                                </Box>
                                {data?.afterPlay?.[gameDataName]?.[
                                  rewardIndex
                                ]?.[field?.backend_label]?.id === "image" ? (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "start",
                                      gap: "15px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        borderRadius: "8px",
                                        cursor: "pointer",
                                        padding: "14px 15px",
                                        height: "62px",
                                        backgroundColor: "#F9F9FC",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        gap: "15px",
                                        width: "100%",
                                      }}
                                      onClick={(event) => {
                                        if (showEdit) return;
                                        handleDrawerOpen(event);
                                        dispatch({
                                          type: setDrawerImageOption,
                                          payload: {
                                            backend_label: field?.backend_label,
                                            image_option: field?.options,
                                            gameDataName: gameDataName,
                                            game_type: "afterPlay",
                                            action_type: setAfterPlayChange,
                                            rewardIndex: rewardIndex,
                                          },
                                        });
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "15px",
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "12px",
                                            height: "12px",
                                          }}
                                          src={uploadIcon}
                                          alt=""
                                        />
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "start",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            gap: "8px",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              textAlign: "start",
                                              fontFamily: "Lato",
                                              fontSize: "12px",
                                              fontStyle: "normal",
                                              fontWeight: 600,
                                              lineHeight: "14.4px",
                                              color: "#003D86",
                                            }}
                                          >
                                            Upload
                                          </Typography>
                                          <Typography
                                            sx={{
                                              textAlign: "start",
                                              color: "#7E8794",
                                              fontFamily: "Lato",
                                              fontSize: "10px",
                                              fontStyle: "normal",
                                              fontWeight: 400,
                                              lineHeight: "12px",
                                            }}
                                          >
                                            Maximum 2MB. Upload .jpeg or .png,
                                            files. Aspect Ratio 1:1
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          gap: "8px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textAlign: "start",
                                            fontFamily: "Lato",
                                            fontSize: "12px",
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            lineHeight: "14.4px",
                                            color: "#003D86",
                                            textDecoration: "underline",
                                          }}
                                        >
                                          Or select from presets
                                        </Typography>
                                        <Box
                                          sx={{
                                            position: "relative",
                                            width: "80px",
                                            height: "30px",
                                          }}
                                        >
                                          {field?.options?.length > 0 &&
                                            field?.options
                                              ?.slice(0, 3)
                                              ?.map((image, index) => (
                                                <img
                                                  key={index}
                                                  src={image?.url}
                                                  alt=""
                                                  style={{
                                                    width: "30px",
                                                    height: "30px",
                                                    borderRadius: "4px",
                                                    position: "absolute",
                                                    left: `${index * 15}px`,
                                                    zIndex: index,
                                                  }}
                                                />
                                              ))}
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                ) : (
                                  data?.afterPlay?.[gameDataName]?.[
                                    rewardIndex
                                  ]?.[field?.backend_label]?.id === "color" && (
                                    <Box sx={{ width: "100%" }}>
                                      <ColorInput
                                        disable={showEdit}
                                        value={
                                          data?.afterPlay?.[gameDataName]?.[
                                            rewardIndex
                                          ]?.[
                                            field?.options?.find(
                                              (o) => o.value === "color"
                                            )?.backend_label
                                          ]
                                        }
                                        onChange={(color) =>
                                          handleAfterPlayChange(
                                            field?.options?.find(
                                              (o) => o.value === "color"
                                            )?.backend_label,
                                            color,
                                            rewardIndex,
                                            {}
                                          )
                                        }
                                      />
                                    </Box>
                                  )
                                )}
                              </Box>
                            ) : field?.input_type === "image_upload" ? (
                              <Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                    gap: "15px",
                                    mt: "10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      borderRadius: "8px",
                                      cursor: "pointer",
                                      padding: "14px 15px",
                                      height: "62px",
                                      backgroundColor: "#F9F9FC",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      gap: "15px",
                                      width: "100%",
                                    }}
                                    onClick={(event) => {
                                      if (showEdit) return;
                                      handleDrawerOpen(event);
                                      dispatch({
                                        type: setDrawerImageOption,
                                        payload: {
                                          rewardIndex: rewardIndex,
                                          backend_label: field?.backend_label,
                                          image_option: field?.options,
                                          gameDataName: gameDataName,
                                          game_type: "afterPlay",
                                          action_type: setAfterPlayChange,
                                        },
                                      });
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: data?.afterPlay?.[
                                          gameDataName
                                        ]?.[rewardIndex]?.[field?.backend_label]
                                          ? "flex"
                                          : "none",
                                        gap: "8px",
                                      }}
                                    >
                                      <img
                                        style={{
                                          borderRadius: "6px",
                                          height: "65px",
                                          width: "65px",
                                        }}
                                        src={
                                          data?.afterPlay?.[gameDataName]?.[
                                            rewardIndex
                                          ]?.[field?.backend_label]
                                        }
                                        alt=""
                                      />
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          alignItems: "flex-start",
                                          gap: 1,
                                        }}
                                      >
                                        <Typography
                                          // onClick={handleSelectImageClick}
                                          sx={{
                                            cursor: "pointer",
                                            fontSize: "12px",
                                            fontWeight: 600,
                                            fontFamily: "Lato",
                                            lineHeight: "14.4px",
                                            color: "#29CC6A",
                                            textDecoration: "underline",
                                          }}
                                        >
                                          Change
                                        </Typography>
                                        {/* <Typography
                                          sx={{
                                            fontFamily: "Lato",
                                            fontSize: "10px",
                                            fontWeight: 400,
                                            lineHeight: "12px",
                                            color: "#7E8794",
                                            textAlign: "left",
                                          }}
                                        >
                                          {"No file selected"}
                                        </Typography> */}
                                      </Box>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: data?.afterPlay?.[
                                          gameDataName
                                        ]?.[rewardIndex]?.[field?.backend_label]
                                          ? "none"
                                          : "flex",
                                        alignItems: "center",
                                        gap: "15px",
                                      }}
                                    >
                                      <img
                                        style={{
                                          width: "12px",
                                          height: "12px",
                                        }}
                                        src={uploadIcon}
                                        alt=""
                                      />
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "start",
                                          justifyContent: "center",
                                          flexDirection: "column",
                                          gap: "8px",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textAlign: "start",
                                            fontFamily: "Lato",
                                            fontSize: "12px",
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            lineHeight: "14.4px",
                                            color: "#003D86",
                                          }}
                                        >
                                          Upload
                                        </Typography>
                                        <Typography
                                          sx={{
                                            textAlign: "start",
                                            color: "#7E8794",
                                            fontFamily: "Lato",
                                            fontSize: "10px",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "12px",
                                          }}
                                        >
                                          Maximum 2MB. Upload .jpeg or .png,
                                          files. Aspect Ratio 1:1
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: "8px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          textAlign: "start",
                                          fontFamily: "Lato",
                                          fontSize: "12px",
                                          fontStyle: "normal",
                                          fontWeight: 600,
                                          lineHeight: "14.4px",
                                          color: "#003D86",
                                          textDecoration: "underline",
                                        }}
                                      >
                                        Or select from presets
                                      </Typography>
                                      <Box
                                        sx={{
                                          position: "relative",
                                          width: "80px",
                                          height: "30px",
                                        }}
                                      >
                                        {field?.options?.length > 0 &&
                                          field?.options
                                            ?.slice(0, 3)
                                            ?.map((image, index) => (
                                              <img
                                                key={index}
                                                src={image?.url}
                                                alt=""
                                                style={{
                                                  width: "30px",
                                                  height: "30px",
                                                  borderRadius: "4px",
                                                  position: "absolute",
                                                  left: `${index * 20}px`,
                                                  zIndex: index,
                                                }}
                                              />
                                            ))}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            ) : field?.input_type === "json" ? (
                              <Box>
                                {field?.fields?.map((element, elementIndex) => (
                                  <Box
                                    key={elementIndex}
                                    sx={{
                                      width: element?.style?.width
                                        ? element?.style?.width
                                        : "calc(96% + 20px)",
                                    }}
                                  >
                                    <BoldLabel label={element?.label} />
                                    {element?.input_type === "input_box" ? (
                                      <Box>
                                        <MuiInput
                                          maxLength={element?.max_characters}
                                          disable={showEdit}
                                          placeholder={element?.placeholder}
                                          value={
                                            data?.afterPlay?.[gameDataName]?.[
                                              rewardIndex
                                            ]?.[
                                              `${field?.backend_label}_${element?.backend_label}`
                                            ] || ""
                                          }
                                          onChange={(event) =>
                                            handleAfterPlayChange(
                                              `${field?.backend_label}_${element?.backend_label}`,
                                              event,
                                              rewardIndex,
                                              {}
                                            )
                                          }
                                        />
                                      </Box>
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                ))}
                              </Box>
                            ) : null}
                          </Box>
                        );
                      })}
                  </Box>
                  <Box
                    mt="16px"
                    sx={{
                      display: showEdit ? "none" : "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Button
                      sx={{
                        width: "136px",
                        height: "24px",
                        borderRadius: "6px",
                        backgroundColor: "#003D86",
                        fontFamily: "Lato",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#FFFFFF",
                        lineHeight: "14.4px",
                        "&:hover": {
                          backgroundColor: "#003D86",
                        },
                      }}
                      onClick={() =>
                        handleAfterPlayChange("isSaved", true, rewardIndex, {
                          isOpen: false,
                        })
                      }
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
