import { Autocomplete, Box, FormControl, FormHelperText, TextField } from "@mui/material";
import React from "react";
import { FiSearch } from "react-icons/fi";

const MuiSelectAndSearch = ({ options, value, onChange, label, errorMessage, isDisable = false, placeholder, editBoolean }) => {
  return (
    <FormControl
      sx={{ mt: "10px", width: "100%" }}
      variant="outlined"
      size="small"
      error={!!errorMessage}
    >
      <Autocomplete
        disablePortal
        disabled={isDisable}
        id="combo-box-demo"
        getOptionLabel={(value) => value?.name || ""}
        fullWidth
        sx={{
          '& .MuiAutocomplete-inputRoot': {
            padding: "1px 8px",
            borderRadius: "6px",
            height: "32px",
            fontFamily: "Lato",
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "16px",
            '& .MuiAutocomplete-input': {
              padding: "0px",
            },
          },
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000ba",
          },
          ...(editBoolean && {
            '& fieldset': {
              borderColor: 'orange !important',
              borderWidth: '1px',
            },
            '&:hover fieldset': {
              borderColor: 'orange !important',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'orange !important',
            },
          }),
          ...(errorMessage && {
            '& fieldset': {
              borderColor: '#d40e10 !important',
              borderWidth: '1px',
            },
            '&:hover fieldset': {
              borderColor: '#d40e10 !important',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#d40e10 !important',
            },
          }),
        }}
        options={options || []}
        value={value || ""}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <Box sx={{ display: "flex", alignItems: "center", marginRight: '5px' }}>
                  <FiSearch style={{color:"#858D9D", marginRight: '5px', height: "14px", width: "14px" }} />
                </Box>
              ),
            }}
          />
        )}
        renderOption={(props, option) => (
          <li
            {...props}
            style={{
              borderBottom: "1px solid #ccc",
              padding: "8px 15px",
              fontFamily: "Mulish",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "18.2px",
              color: "#0F1828",
            }}
          >
            {option.name}
          </li>
        )}
      />
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default MuiSelectAndSearch;
