import { Avatar, Box, Button, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import UiTable from "../components/UiTable";
import TableTotalCount from "../components/TableTotalCount";
import SearchActionComponent from "../components/SearchActionComponent";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import copyIcon from "../assets/copy.png";
import deleteIcon from "../assets/delete.png";
import { BootstrapTooltip } from "../utils/tooltip";
import CustomConfirmationDialog from "../UIComponents/CustomConfirmationDialog";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../components/Loader";
import { FaTemperatureHigh, FaTemperatureLow } from "react-icons/fa6";
import CommonDateFilterPanel from "../components/Filter/CommonDateFilterPanel";
import spinnerIcon from "../assets/spinner.svg";
import SwipeableDrawerComponent from "../components/SwipeableDrawerComponent ";
import CustomFilter from "../components/Filter/CustomFilter";
import FilteredResults from "../components/FilteredResults";
import { formatDateNew } from "../utils/formateDate";
import dayjs from "dayjs";
import usePermission from "../utils/permissions";
import ToggleColumn from "../UIComponents/ToggleColumn";
const dateFilterLayout = {
  select: "ALL_TIME",
  from_time: "",
  to_time: "",
};

const filterSelectOptions = [
  { label: "Custom", value: "CUSTOM" },
  { label: "Today", value: "TODAY" },
  { label: "Yesterday", value: "YESTERDAY" },
  { label: "This Week", value: "THIS_WEEK" },
  { label: "This Month", value: "THIS_MONTH" },
  { label: "All Time", value: "ALL_TIME" },
];

const initialFilterOptions = {
  offer_status: [],
  status: [],
  reward_currency: [],
};

//css

const moreFilterButtonStyle = {
  height: "45px",
  padding: "14px 20px 14px 20px",
  borderRadius: "5px",
  border: "1px solid #D9D9D9",
  background: "#fff",
};

const moreFilterTextStyle = {
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "17.5px",
  fontFamily: "Proxima Nova A",
  color: "#26292D",
};

const TData = ()=>{
  return [
    { "key": "id", "displayName": "Rule ID", "flag": true },
    { "key": "name", "displayName": "Rule Name", "flag": true },
    { "key": "description", "displayName": "Rule Description", "flag": true },
    { "key": "rule", "displayName": "Rule Type", "flag": true },
    // { "key": "copyBtn", "displayName": "Action", "flag": true }
  ]
}



export default function ViewRules() {

  const [fieldsName, setFieldName] = useState(TData);
  const [columnFields, setColumnFields] = useState([]);

  const [search, setSearch] = useState("");
  const [deleteData, setDeleteData] = useState(false);
  const [flag, setFlag] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [editData, setEditData] = useState(false);
  const anchor = "right";
  const [dateFilter, setDateFilter] = useState(dateFilterLayout);
  const [filterConfig, setFilterConfig] = useState([]);
  const [doneButton, setDoneButton] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showFilter, setShowFilter] = useState([]);
  const [filter, setFilter] = useState({
    right: false,
  });
  const [rules, setRules] = useState({
    data: [],
    paginationModel: {
      pageSize: 10,
      page: 0,
    },
    totalCount: 0,
  });
  const navigate = useNavigate();
  const IdRef = useRef("");
  const permission = usePermission();
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const isAuth = useSelector((store) => store.AuthReducer.token);
  const token = useSelector((store) => store.AuthReducer.token);

  function handleDelete(e, params) {
    e.stopPropagation();
    IdRef.current = params?.id;
    setDeleteData(true);
    // console.log(`delete ${params.id}`);
  }

  function handleDeleteDataByID(id) {
    if (!id) return;
    console.log(`delete ${id}`);
    toast.success("Rules Deleted Successfully");
    //   const url=""
    // axios.delete(url,{id}).then((res)=>{
    //   toast.success("rule deleted successfully")
    // setFlag(prev=>!prev)
    // }).catch((err)=>{
    //   console.log("error while deleting",err)
    // })
  }
  
  const handleToggleColumn = (i)=>{
    console.log(i, "index");
    const data = fieldsName.map((e,index)=>{
      if(index===i){
        e.flag =!e.flag
      }
      return e
    })

    setFieldName(data);
    
  }

  function handlePriorityChange(id) {
    console.log(id);
  }

  function handlePriority(event, params) {
    event.preventDefault();
    // console.log(params)
    // // if (!id) return;
    // if(!params.value){
    //   console.log(`priority ${params.id}`);
    // }
    IdRef.current = params?.id;
    if (!params.value) {
      if (params.field === "low") {
        setMessage("Do you really want to set priority as high?");
      } else {
        setMessage("Do you really want to set priority as low?");
      }
      setEditData(true);
    } else {
      if (params.field === "low") {
        toast.warning("Priority is already low");
      } else {
        toast.warning("Priority is already high");
      }
    }
  }

  function handleCopy(e, params) {
    e.stopPropagation();
    if (permission?.CREATE) {
      navigate("/create-rules", { state: { id: params.id } });
    }
  }

  const handleRowClick = (params) => {
    navigate("/preview-rule", { state: { id: params.id } });
    // if (permission?.CREATE) {
    // }
  };

  // const getRuleSetFunction = (status,data) => {
  //   setLoading(true);
  //   if (status === "done") {
  //     setShowFilter(filterConfig);
  //   }

  //   let params = { table: "rule_set" };
  //   if (search) {
  //     params.search = search;
  //   }
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };

  //   if (!status && data) {
  //     filterConfig?.forEach((res) => {
  //       if (res?.state?.length > 0) {
  //         params[res?.name] = res?.state;
  //       }
  //     });
  //   } else if (status === "clear") {
  //     data?.forEach((res) => {
  //       if (res?.state?.length > 0) {
  //         params[res?.name] = res?.state;
  //       }
  //     });
  //   } else if (status === "done") {
  //     filterConfig?.forEach((res) => {
  //       if (res?.state?.length > 0) {
  //         params[res?.name] = res?.state;
  //       }
  //     });
  //   }

  //   axios
  //     .get(
  //       `${BaseUrl}/api/rules?page=${
  //         rules?.paginationModel.page + 1
  //       }&pageSize=${rules?.paginationModel.pageSize}`,
  //       { params, ...config }
  //     )
  //     .then((response) => {
  //       console.log(response.data.results, "rule");
  //       let formateData = response?.data?.results?.map((e) => {
  //         return {
  //           id: e.id,
  //           name: e.name,
  //           description: e.description,
  //           copyBtn: copyIcon,
  //           deleteBtn: deleteIcon,
  //           low: e.name.length>10,
  //           high: e.name.length>10?false:true,
  //         };
  //       });
  //       setRules((prev) => ({
  //         ...prev,
  //         data: formateData ?? [],
  //         totalCount: response?.data?.ruleSetCount ?? 0,
  //         // totalCount: 30,
  //       }));
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setLoading(false);
  //     });
  // };

  const getRuleSetFunction = async (status, allData,isPagination = false) => {
    setLoading(true);

    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const token = isAuth;

    if(!isPagination){
      const paginationValue = {
        page: 0,
        pageSize:  rules?.paginationModel.pageSize,
      };
        handlePaginationModel(paginationValue);
      }

    const body = {
      page: isPagination ? rules?.paginationModel.page + 1 : 1,
      pageSize: rules?.paginationModel.pageSize,
      ...(search && { search }), // Include search parameter if it exists
    };

    if (status === "done") {
      setShowFilter(filterConfig);
    }

    if (!status) {
      showFilter?.forEach((res) => {
        if (res?.state?.length > 0) {
          body[res?.name] = res?.state;
        }
      });
    } else if (status === "clear") {
      allData?.forEach((res) => {
        if (res?.state?.length > 0) {
          body[res?.name] = res?.state;
        }
      });
    } else if (status === "done") {
      filterConfig?.forEach((res) => {
        if (res?.state?.length > 0) {
          body[res?.name] = res?.state;
        }
      });
    }

    // Include dateFilter if necessary
    if (dateFilter?.select) {
      body["day"] = dateFilter?.select;
    }

    // Include custom dateFilter if necessary
    if (
      dateFilter?.select &&
      dateFilter?.select === "CUSTOM" &&
      dateFilter?.to_time &&
      dateFilter?.from_time
    ) {
      let startDate = formatDateNew(dateFilter?.from_time ?? "");
      let endDate = formatDateNew(dateFilter?.to_time ?? "");
      body["dateStart"] = startDate;
      body["dateEnd"] = endDate;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(`${baseUrl}/api/rules`, body, config);

      console.log(response.data.results, "rule");
      let formateData = response?.data?.results?.map((e) => {
        return {
          id: e.id,
          name: e.name,
          description: e.description,
          rule: e.rules[0]?.cashback_type,
          copyBtn: copyIcon,
          deleteBtn: deleteIcon,
          low: e.name.length > 10,
          high: e.name.length > 10 ? false : true,
        };
      });
      setStartDate(response.data.day_start_date);
      setEndDate(response.data.day_end_date);
      setRules((prev) => ({
        ...prev,
        data: formateData ?? [],
        totalCount: response?.data?.ruleSetCount ?? 0,
      }));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleRemove = (name, value) => {
    setFilterConfig((prevOptions) => {
      const updatedOptions = [...prevOptions];
      console.log(updatedOptions, "testing");
      const updatedData = updatedOptions?.map((filter) =>
        filter?.name === name
          ? {
              ...filter,
              state: filter.state.filter((res) => res !== value),
            }
          : filter
      );
      return updatedData;
    });
  };

  const removeFilters = (name, value) => {
    handleRemove(name, value);
    const latestData = showFilter.map((filter) =>
      filter?.name === name
        ? {
            ...filter,
            state: filter.state.filter((res) => res !== value),
          }
        : filter
    );
    setShowFilter((prevOptions) => {
      const updatedData = prevOptions?.map((filter) =>
        filter?.name === name
          ? {
              ...filter,
              state: filter.state.filter((res) => res !== value),
            }
          : filter
      );

      return updatedData;
    });

    getRuleSetFunction("clear", latestData);
  };

  const handleDateFilter = (name, value) => {
    setDateFilter((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (value === "ALL_TIME") {
      setDateFilter((prev) => ({
        ...prev,
        from_time: { unset: true },
        to_time: { unset: true },
      }));
    }

    if (value === "CUSTOM") {
      setDateFilter((prev) => ({
        ...prev,
        from_time: { unset: true },
        to_time: { unset: true },
      }));
    }
  };
  console.log(dateFilter, "dateFilter");
  const handleClearFilter = (initializeValueByName) => () => {
    setFilterConfig((prev) => {
      const updatedData = prev?.map((filter) => ({ ...filter, state: [] }));
      return updatedData;
    });
  };

  const handleDoneButton = () => {
    // setFlag(prev=>!prev)
    setDoneButton((prev) => !prev);
  };

  const handleFilterState = (name, value, checked, status) => {
    if (status === "clear") {
      setFilterConfig((prev) => {
        const updatedData = prev?.map((filter) =>
          filter?.name === name ? { ...filter, state: [] } : filter
        );
        return updatedData;
      });
      return;
    }
    if (checked) {
      setFilterConfig((prev) => {
        const updatedData = prev?.map((filter) =>
          filter?.name === name
            ? { ...filter, state: [...filter?.state, value] }
            : filter
        );
        return updatedData;
      });
    } else {
      setFilterConfig((prev) => {
        const updatedData = prev?.map((filter) =>
          filter?.name === name
            ? {
                ...filter,
                state: filter.state.filter((res) => res !== value),
              }
            : filter
        );
        return updatedData;
      });
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setFilter((prev) => ({ ...prev, [anchor]: open }));
  };

  const fetchFilters = async () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const token = isAuth;

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Adjust content type as needed
    };
    try {
      const response = await axios.get(
        `${baseUrl}/api/filters?page=viewRules`,
        {
          headers,
        }
      );
      if (response) {
        const config = response.data;
        let obj = {};
        const data = config.map((res) => {
          if (!obj.hasOwnProperty(res?.name)) {
            obj[res?.name] = [];
          }
          return { ...res, state: [] };
        });

        // setFilterOptions(obj);
        setFilterConfig(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(filterConfig, "dateFilter");

  const columns = [
    {
      field: "id",
      headerName: "Rule ID",
      minWidth: 100,
      // flex:0.5,
      renderCell: (params) => {
        if (!params?.value) {
          return "NA";
        }
        return (
          <Box
            paddingRight="auto"
            marginLeft="20px"
            fontFamily="ABeeZee"
            fontWeight={400}
            fontSize="13px"
            lineHeight="15.37px"
            
            letterSpacing="2%"
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Rule Name",
      // flex:1.5,
      minWidth: 250,
      renderCell: (params) => {
        if (!params?.value) {
          return "NA";
        }

        return (
          <BootstrapTooltip title={params.value}>
            <Box
              fontFamily="Roboto"
              fontWeight={600}
              fontSize="13px"
              lineHeight="15.23px"
              letterSpacing="2%"
              color="#043A87"
              
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "description",
      headerName: "Rule Description",
      minWidth: 350,
      // flex:2.5,
      renderCell: (params) => {
        if (!params?.value) {
          return "NA";
        }

        return (
          <BootstrapTooltip title={params?.value}>
            <Typography
              sx={{
                overflow: "hidden",
              }}
              fontFamily="ABeeZee"
              fontWeight={400}
              fontSize="13px"
              lineHeight="15.37px"
              
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Typography>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "rule",
      headerName: "Rule Type",
      minWidth: 200,
      // flex:1,
      renderCell: (params) => {
        if (!params?.value) {
          return "NA";
        }

        return (
          <BootstrapTooltip title={params?.value}>
            <Box
              fontFamily="ABeeZee"
              fontWeight={400}
              fontSize="13px"
              lineHeight="15.37px"
              
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    // {
    //   field: "integration_status",
    //   headerName: "Rewards Currency",
    //   minWidth: 200,
    //   renderCell: (params) => {
    //     if (!params?.value) {
    //       return "NA";
    //     }

    //     return <Box>{params?.value}</Box>;
    //   },
    // },
    {
      field: "copyBtn",
      headerName: "Action",
      minWidth: 100,
      // flex: 0.6,
      renderCell: (params) => {
        //   if (!params?.value) {
        //     return "NA";
        //   }

        return (
          <BootstrapTooltip title={permission.CREATE?"Copy Rule":"You don't have permission"}> 
          <Box
            // sx={{
            //   width: "100%",
            //   height: "100%",
            //   display: "grid",
            //   placeItems: "center",
            // }}
            marginLeft="5px"
            onClick={(e) => handleCopy(e, params)}
          >
            <img
              style={{ height: "24px", width: "24px" }}
              src={params?.value}
              alt="copy"
            />
          </Box>
          </BootstrapTooltip>
        );
      },
    },

    // {
    //   field: "deleteBtn",
    //   headerName: "",
    //   // minWidth: 150,
    //   flex: 1,
    //   renderCell: (params) => {
    //     //   if (!params?.value) {
    //     //     return "NA";
    //     //   }

    //     return (
    //       <Box
    //         sx={{
    //           width: "100%",
    //           height: "100%",
    //           display: "grid",
    //           placeItems: "center",
    //         }}
    //         onClick={(e) => handleDelete(e, params)}
    //       >
    //         {/* {params?.value} */}
    //         <img
    //           style={{ height: "24px", width: "24px" }}
    //           src={params?.value}
    //           alt="delete"
    //         />
    //       </Box>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    fetchFilters();
  }, []);

  useEffect(() => {
    let debounceTimer;
    console.log("hit");
    if (dateFilter.select !== "CUSTOM") {
      if (flag) {
        // Fetch data when flag changes
        // getRuleSetFunction("");
           getRuleSetFunction("",null,true);
        setFlag(false);
      } else {
        // Apply debouncing effect only on search
        debounceTimer = setTimeout(() => {
          // getRuleSetFunction("");
          getRuleSetFunction("",null,true);
        }, 0);
      }
    }
    // Cleanup function
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [
    rules?.paginationModel?.page,
    rules?.paginationModel?.pageSize,
    // search,
    // doneButton,
    // dateFilter.select,
  ]);
  useEffect(() => {
    let debounceTimer;
    console.log("hit");
    if (dateFilter.select !== "CUSTOM") {
      if (flag) {
        // Fetch data when flag changes
        getRuleSetFunction("");
        setFlag(false);
      } else {
        // Apply debouncing effect only on search
        debounceTimer = setTimeout(() => {
          getRuleSetFunction("");
        }, 0);
      }
    }
    // Cleanup function
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [
    dateFilter.select,
  ]);


  useEffect(() => {
    let debounceTimer;
    console.log("hit");
    if (dateFilter.select !== "CUSTOM") {
      if (flag) {
        // Fetch data when flag changes
        getRuleSetFunction("");
        setFlag(false);
      } else {
        // Apply debouncing effect only on search
        debounceTimer = setTimeout(() => {
          getRuleSetFunction("");
        }, 1000);
      }
    }
    // Cleanup function
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [
    search,
  ]);

  useEffect(() => {
    // if(dateFilter?.select=="CUSTOM"){
    //   getRuleSetFunction("done");
    // }

    let debounceTimer;
    if (
      dateFilter?.select == "CUSTOM" &&
      dateFilter?.from_time &&
      dateFilter?.to_time &&
      typeof dateFilter?.from_time === "string" &&
      typeof dateFilter?.to_time === "string"
    ) {
      // Apply debouncing effect only on search
      debounceTimer = setTimeout(() => {
        getRuleSetFunction("", null, true);
      }, 0);
    }

    // Cleanup function
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [
    // dateFilter?.from_time,
    // dateFilter?.to_time,
    rules?.paginationModel?.page,
    rules?.paginationModel?.pageSize,
    // search,
    // doneButton,
  ]);
  useEffect(() => {
    // if(dateFilter?.select=="CUSTOM"){
    //   getRuleSetFunction("done");
    // }

    let debounceTimer;
    if (
      dateFilter?.select == "CUSTOM" &&
      dateFilter?.from_time &&
      dateFilter?.to_time &&
      typeof dateFilter?.from_time === "string" &&
      typeof dateFilter?.to_time === "string"&&
      dateFilter?.from_time!== "Invalid Date" &&
      dateFilter?.to_time!== "Invalid Date"
    ) {
    
      debounceTimer = setTimeout(() => {
        getRuleSetFunction("");
      }, 800);
    }

    // Cleanup function
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [
    dateFilter?.from_time,
    dateFilter?.to_time,
  ]);


  useEffect(() => {
    // if(dateFilter?.select=="CUSTOM"){
    //   getRuleSetFunction("done");
    // }

    let debounceTimer;
    if (
      dateFilter?.select == "CUSTOM" &&
      dateFilter?.from_time &&
      dateFilter?.to_time &&
      typeof dateFilter?.from_time === "string" &&
      typeof dateFilter?.to_time === "string"
    ) {
      // Apply debouncing effect only on search
      debounceTimer = setTimeout(() => {
        getRuleSetFunction("");
      }, 1000);
    }

    // Cleanup function
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [

    search,
    // doneButton,
  ]);

  useEffect(() => {
    getRuleSetFunction("done");
  }, [doneButton]);

  const handlePaginationModel = (value) => {
    if (!value) return;
    setRules((prev) => ({ ...prev, paginationModel: value }));
  };

  useEffect(() => {
    // Step 1: Filter TData columns where flag is true
    let  filteredTData = fieldsName?.filter(
      (column) => column.flag === true
    );

    filteredTData = [...filteredTData, { "key": "copyBtn", "displayName": "Action", "flag": true }];

    // Step 2: Filter columns where field matches key from filteredTData
    const filteredColumns = columns.filter((column) =>
      filteredTData.some(
        (filteredColumn) => filteredColumn.key === column.field
      )
    );

    const finalColumns = [...filteredColumns];

    setColumnFields(finalColumns);
  }, [fieldsName]);

  useEffect(() => {
    console.log("date");
    if (startDate && endDate) {
      setDateFilter((prev) => ({
        ...prev,
        from_time: dayjs(new Date(startDate)),
        to_time: dayjs(new Date(endDate)),
      }));
    }
  }, [startDate, endDate]);

  // if (loading) {
  //   return <Loader />;
  // }
  // console.log(rules?.paginationModel?.page)


  return (
    <Box mx="30px">
      {/* <ToastContainer autoClose={2000}/> */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginTop="31px"
        marginBottom="20px"
        gap="20px"
      >
        <Box
          sx={{ display: "flex", gap: "20px", justifyContent: "space-between" }}
          width="750px"
        >
          <Box width="320px">
            {/* <Box > */}

            <SearchActionComponent
              value={search}
              setState={setSearch}
              placeholder={"Search By Name And Description"}
            />
          </Box>
          <CommonDateFilterPanel
            dateFilter={dateFilter}
            filterSelectOptions={filterSelectOptions}
            handleDateFilter={handleDateFilter}
          />
        </Box>

        <Button
          sx={moreFilterButtonStyle}
          startIcon={
            <Avatar
              src={spinnerIcon}
              sx={{
                width: "16px",
                height: "16px",
              }}
            />
          }
          onClick={() => {
            toggleDrawer(anchor, true)();
          }}
          variant={"text"}
        >
          <Typography
            fontFamily="Proxima Nova A"
            lineHeight="17.05px"
            fontSize="14px"
            sx={moreFilterTextStyle}
            
          >
            {"More Filters"}
          </Typography>
        </Button>
        <BootstrapTooltip
          title={permission?.CREATE ? "" : "You don't have permission"}
          placement="top-start"
        >
          <span>
            <Button
              style={{
                backgroundColor: permission?.CREATE ? "black" : "gray",
                height: "43px",
                // width: "120px",
                padding: "14px 20px 14px 20px",
                borderRadius: "5px",
                color: "#FFF",
                fontFamily: "ABeeZee",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                textTransform: "capitalize",
              }}
              disabled={!permission?.CREATE}
              onClick={() => navigate("/create-rules")}
            >
              Create Rule
            </Button>
          </span>
        </BootstrapTooltip>
      </Box>
      <Box sx={{ marginBottom: "20px" }}>
        {showFilter && (
          <FilteredResults options={showFilter} handleRemove={removeFilters} />
        )}
      </Box>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", }}>

        <TableTotalCount totalCount={rules?.totalCount} text={"All Rules"} />
        <ToggleColumn tableFields={fieldsName} handleToggleColumn={handleToggleColumn} disabledIndex={1} />
        </Box>
        <UiTable
          row={rules?.data || []}
          columns={columnFields || []}
          totalCount={rules?.totalCount}
          paginationModel={rules?.paginationModel}
          setPaginationModel={handlePaginationModel}
          handleRowClick={handleRowClick}
          loading={loading}
        />
      </Box>
      {/* <CustomConfirmationDialog
        open={deleteData}
        setOpen={setDeleteData}
        deleteById={handleDeleteDataByID}
        prop={IdRef.current}
        status="delete"
        message={`Are you sure you want to delete this rule?`}
      /> */}
      {/* more Filter */}
      <SwipeableDrawerComponent
        onOpen={toggleDrawer(anchor, true)}
        isOpen={filter.right}
        onClose={toggleDrawer(anchor, false)}
        anchor={anchor}
      >
        <CustomFilter
          filterOptions={filterConfig}
          handleClear={handleClearFilter(initialFilterOptions)}
          handleDoneButton={handleDoneButton}
          handleFilterState={handleFilterState}
          onCLose={toggleDrawer(anchor, false)}
        />
      </SwipeableDrawerComponent>
      <CustomConfirmationDialog
        open={editData}
        setOpen={setEditData}
        deleteById={handlePriorityChange}
        prop={IdRef.current}
        status="delete"
        message={message}
      />
    </Box>
  );
}
