import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import closeBtnLogo from "../assets/closeBtnlogo.png"
import questionImage from "../assets/questionImage.svg"

function CustomConfirmationDialog({
  open = false,
  isGroup = false,
  value,
  handleState = () => {},
  setOpen = () => {},
  status = "",
  deleteById = () => {},
  blockById = () => {},
  approveById = () => {},
  denyById = () => {},
  createOffer = () => {},
  message = "Are you sure you want to send this offer request to the admin for approval.",
  riderId,
  prop = false,
  confirmationText = "confirm",
  cancelText = "cancel"
}) {
  const handleCloseDialog = () => {
    if (isGroup) {
      handleState(status, false);
    } else {
      setOpen(false);
    }
  };

  const handleConfirm = (e) => {
    if (status) {
      switch (status) {
        case "delete":
          deleteById(prop || e);
          break;
        case "block":
          blockById(prop || riderId || e);
          break;
        case "approve":
          approveById(prop || e);
          break;
        case "deny":
          denyById(prop || e);
        case "create":
          createOffer(prop || e);
          break;
        default:
          break;
      }
    }
    handleCloseDialog();
  };

  return (
    <Dialog
      open={open}
      style={{ zIndex: 2000, backgroundColor: "transparent" }}
      onClose={handleCloseDialog}
    >
      <Box sx={{
        width: "475px",
        height: "fit-content",
        backgroundColor: "#fff",
        borderRadius: "20px",
      }}>
           <Button
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
              cursor: "pointer",
              minWidth: "0px",
            }}
            onClick={handleCloseDialog}
          >
            <img src={closeBtnLogo} alt="" />
          </Button>
          <Box sx={{display:"flex",justifyContent:"center",mt:"31px"}}>
          <Box sx={{display:"flex",justifyContent:"center",width:"315px"}}>
            <Box>
            <Box sx={{display:"flex",justifyContent:"center"}}>
              <img style={{
                height:"66px",
                width:"66px",
              }} src={questionImage} alt="" />
            </Box>

              <Box mt="23px">
                <Typography sx={{
                  fontFamily:"Lato",
                  fontSize:"18px",
                  fontWeight:"700",
                  lineHeight:"21.6px",
                  color:"#0B1B32",
                  textAlign:"center"
                }}>
                  Confirm Submission
                </Typography>
                <Typography sx={{
                  fontFamily:"Lato",
                  fontSize:"14px",
                  fontWeight:"400",
                  lineHeight:"20px",
                  color:"#7E8794",
                  textAlign:"center",
                  mt:"6px"
                }}>
                {message}
                </Typography>
              </Box>

              <Box sx={{display:"flex",justifyContent:"center",gap:"17px",mt:"20px",mb:"30px"}}>
                <Button sx={{
                  width:"136px",
                  height:"30px",
                  borderRadius:"6px",
                  backgroundColor:"#E8F0F9",
                  color:"#003D86",
                  fontFamily:"Lato",
                  fontSize:"14px",
                  fontWeight:"500",
                  lineHeight:"16.8px",
                  textTransform:"capitalize",
                  "&:hover": {
                    backgroundColor: "#E8F0F9",
                  },
                }} onClick={handleCloseDialog}>
                  {cancelText}
                </Button>
                <Button sx={{
                  width:"136px",
                  height:"30px",
                  borderRadius:"6px",
                  backgroundColor:"#003D86",
                  color:"white",
                  fontFamily:"Lato",
                  fontSize:"14px",
                  fontWeight:"500",
                  lineHeight:"16.8px",
                  textTransform:"capitalize",
                  "&:hover": {
                    backgroundColor: "#003D86",
                  },
                }} onClick={handleConfirm}>
                  {confirmationText}
                </Button>
              </Box>
            </Box>
       
          </Box>
          </Box>
      </Box>
     
    </Dialog>
  );
}

export default CustomConfirmationDialog;