import React from 'react'
import OfferStepper from './OfferStepper'
import PreviewOffer from '../../pages/PreviewOffer'

export default function ApproveOffer() {
  return (
    <div>
              {/* <OfferStepper/> */}
              <PreviewOffer />
    </div>
  )
}
